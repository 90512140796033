import React from "react";
import { Helmet } from "react-helmet";

import "./service.css";

import Contact from "../../components/contact/Contact";

import MApp1 from "../../images/assets/icons/App_Dev.png";

const Application = () => {
  return (
    <div>
      <Helmet>
        <title>pixXmo | Mobile Application Development</title>
      </Helmet>
      <div className="seo">
        <h1>
          <strong>Mobile Application Development</strong>
        </h1>
        <h3>
          What is quicker, easier, and more accessible is probably an
          application. How many times do we hear someone saying that there is an
          app for this? Applications that are usually referred to as apps are
          taking the entire industry and how we see and access things by storm.
          We design, deploy and develop these apps at minimalist prices with a
          focus on conveying what is yours.
        </h3>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>
            <strong>Native:</strong> Specifically designed and developed for a
            particular platform or device.
          </h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>
            <strong>Hybrid:</strong> Combination of both native and web
            applications. Designed and developed for any platform from a single
            code base.
          </h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>
            <strong>Web:</strong> An application that is designed and developed
            to deliver web pages on different web platforms for any device.
          </h4>
        </span>
        <h2>
          <strong>Application Development Lifecycle</strong>
        </h2>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>
            <strong>Planning:</strong> Involves completing business analysis and
            creating a mobile strategy.
          </h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>
            <strong>Technica:</strong> Responsible for describing all technical
            details and requirements.
          </h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>
            <strong>Prototyping:</strong> sketch, wireframes, and UX/UI designs
            are created for the app
          </h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>
            <strong>Developing:</strong> includes front-end and back-end coding
            segments
          </h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>
            <strong>Quality:</strong> testing the applications and device
            capability to ensure that the app is working.
          </h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>
            <strong>Publishing:</strong> after finalizing and testing the app is
            published to the app store.
          </h4>
        </span>
        <hr />
        <h2>
          <strong>Why do you need an application?</strong>
        </h2>
        <h5>
          Mobile Application helps your business to improve direct communication
          with your clients and customers. It offers valuable marketing
          opportunities and helps you to reach your target audience, as well as
          help you to put ahead of your competitors.
        </h5>
        <div className="row">
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Create a direct marketing channel</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Provide value to your customers</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Build brand recognition</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Increase brand awareness</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Improve customer engagement</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Increased visibility</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Increased accessibility</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Better customer service</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Cultivate customer loyalty</h4>
            </div>
          </div>
        </div>
        <hr />
        <h2>
          <strong>Why did you choose us?</strong>
        </h2>
        <h5>
          Pixxmo helps businesses to build, manage, and modernize applications
          using advanced technologies. Our applications are powered by
          cutting-edge and open source technology that ensures products are
          scalable, customizable, maintainable, and cost-effective.
        </h5>
        <h5>
          We have a Cloud-Native development approach towards building
          applications. This allows businesses to continuously release, get to
          market faster, as well as bring in resiliency and flexibility.
        </h5>
        <h5>
          We help businesses to develop an end-to-end mobile application
          strategy to provide an excellent user experience and functionality
          even on the smallest of screens.
        </h5>
        <div className="row">
          <div className="col-lg-4">
            <div className="seobox">
              <h4>App Branding</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>App Version</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Manual Release Mode</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Minimum OS Support</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Live Build Testing</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Localization and User Regions</h4>
            </div>
          </div>
        </div>
        <hr />
        <h2>
          <strong>Technologies or Tools we use</strong>
        </h2>
        <h5>
          Our products are built on top of modern frameworks and technologies
        </h5>
        <div className="row">
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Python</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Java</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>JavaScript</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Kotlin</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Ruby on Rails</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Flutter</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>React Native</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Ionic</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Cordova</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Meteor</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Mobile Angular UI</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>PhoneGap</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>FireBase</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>MongoDB</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>PostgreSQL</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>MySQL</h4>
            </div>
          </div>
        </div>
        <hr />
      </div>
      {/* Request a Quote Section */}
      <div className="servicecontact">
        <h1>
          How we can help{" "}
          <span>
            <strong>your business grow.</strong>
          </span>
        </h1>
        <div className="row">
          <div className="col-lg-6">
            <div className="serviceimg">
              <img src={MApp1} alt="Our Services" />
            </div>
          </div>
          <div className="col-lg-6 servicecnt">
            <Contact />
          </div>
        </div>
      </div>
      {/* Ends */}
    </div>
  );
};

export default Application;
