import React from "react";
import "./home.css";

// Clients Images
import x4us from "../../images/clients/x4us.png";
import Briller from "../../images/clients/Briller.png";
import FrontFace from "../../images/clients/frontfaceband.png";
import GoCarMats from "../../images/clients/gocarmart.png";
import Hans from "../../images/clients/hans.png";
import JK from "../../images/clients/jk.png";
import OldMonk from "../../images/clients/oldmonk.png";
import Sparks from "../../images/clients/sparks.png";
import ThepureEarth from "../../images/clients/thepureearth.png";
import Verge from "../../images/clients/Verge.png";
import Weihz from "../../images/clients/weihz.png";

import Web from "../../images/assets/Design.png";

import MACP from "../../images/certify/MACPBlueEN.jpg";

// Services Icons
import Web1 from "../../images/assets/icons/Design.png";
import App1 from "../../images/assets/icons/app.png";
import SEO1 from "../../images/assets/icons/seo.png";
import CSD1 from "../../images/assets/icons/custom.png";
import PPC1 from "../../images/assets/icons/PPC.png";
import SMM1 from "../../images/assets/icons/smm.png";
import Graphic1 from "../../images/assets/icons/graphic.png";

import Contact from "../../components/contact/Contact";

const Home = () => {
  return (
    <div>
      <div className="home">
        {/* Main Section */}
        <section id="main">
          <header className="main">
            <div className="row">
              <div className="col-lg-6">
                <div className="home1">
                  <h1>
                    <span className="pixxmo">pixXmo IT Solutions Company</span>
                  </h1>
                  <h4>
                    We are a bunch of enthusiastic and creative minds to help
                    you with the best solutions for competing in the business
                    world you need. We assure you that we will take care of all
                    your promotions and other related activities so that you can
                    concentrate on delivering quality products and services to
                    your customers.
                  </h4>
                  <h4>
                    At pixxmo, we will help you to attract more customers and
                    increase your profits with seamless web solutions ranging
                    from search engine optimization, local SEO, content writing,
                    email marketing, website designing and development,
                    application development, social media marketing, Custom
                    Software Development, etc.
                  </h4>
                  <h2>Let's Start a New Project Together</h2>
                  <div className="quotep">
                    <a href="/contactus" className="quotelink1">
                      <h4>
                        Request A Quote <i className="fas fa-chevron-right"></i>
                      </h4>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 homeimg">
                <img src={Web} alt="" />
              </div>
            </div>
          </header>
        </section>
        {/* Ends */}

        {/* Why Choose Us */}
        <div className="dm">
          <h1>
            <strong>Why Choose Us?</strong>
          </h1>
          <h4>
            Pixxmo is India based IT solutions company that helps startups,
            SMEs, and businesses to grow their online presence, increase reach
            and engagement, and build brand reputation. We are specialized in
            providing all kinds of marketing and development services such as
            social media marketing, content marketing, branding, email
            marketing, search engine optimization, professional
            websites/applications ranging from simple corporate
            websites/applications to the most complex and sophisticated
            eCommerce platforms.
          </h4>
          <div className="row">
            <div className="col-lg-4">
              <span className="d-flex align-items-stretch ">
                <i className="fas fa-fire"></i>
                <h4>
                  <b>Advanced Tools</b>
                </h4>
              </span>
            </div>
            <div className="col-lg-4">
              <span className="d-flex align-items-stretch ">
                <i className="fas fa-fire"></i>
                <h4>
                  <b>Guaranteed Results</b>
                </h4>
              </span>
            </div>
            <div className="col-lg-4">
              <span className="d-flex align-items-stretch ">
                <i className="fas fa-fire"></i>
                <h4>
                  <b>Dedicated Project Team</b>
                </h4>
              </span>
            </div>
            <div className="col-lg-4">
              <span className="d-flex align-items-stretch ">
                <i className="fas fa-fire"></i>
                <h4>
                  <b>Local Search Optimization</b>
                </h4>
              </span>
            </div>
            <div className="col-lg-4">
              <span className="d-flex align-items-stretch ">
                <i className="fas fa-fire"></i>
                <h4>
                  <b>Competitors Analysis</b>
                </h4>
              </span>
            </div>
            <div className="col-lg-4">
              <span className="d-flex align-items-stretch ">
                <i className="fas fa-fire"></i>
                <h4>
                  <b>Performance Tracking</b>
                </h4>
              </span>
            </div>
          </div>
        </div>
        {/* Ends */}

        {/* Service Section */}
        <section id="service">
          <div className="service">
            <h1>
              <strong>Our Services</strong>
            </h1>
            <div className="row">
              {/* Website Design and Development */}
              <div className="col-lg-4">
                <div className="box" data-aos="zoom-in">
                  <a href="/website-design-and-development">
                    <div className="boximg">
                      <img src={Web1} alt="Website Design and Development" />
                    </div>
                    <div className="boxtxt">
                      <h3>
                        <strong>Website Design and Development</strong>
                      </h3>
                      <h4>
                        Defying the conventional method of purely coding for a
                        website, we at pixXmo, go through different steps with
                        the client while developing it. From basic research
                        about conveying your thoughts to our analysis of
                        conveying your thoughts through information, we follow
                        an extensive route while creating a website for you.
                      </h4>
                    </div>
                  </a>
                </div>
              </div>
              {/* Social Media Marketing */}
              <div className="col-lg-4">
                <div className="box" data-aos="zoom-in">
                  <a href="/social-media-marketing">
                    <div className="boximg">
                      <img src={SMM1} alt="Social Media Marketing" />
                    </div>
                    <div className="boxtxt">
                      <h3>
                        <strong>Social Media Marketing</strong>
                      </h3>
                      <h4>
                        Social Media Marketing is a great way to engage with
                        customers and build brand reputation. pixXmo helps SMEs,
                        startups, businesses like yours to get more website
                        traffic, increase sales and attract more customers by
                        our expertise in Facebook Ads, Google Ads, content
                        creation, influencer marketing, and proven strategies.
                      </h4>
                    </div>
                  </a>
                </div>
              </div>
              {/* Graphics Designing */}
              <div className="col-lg-4">
                <div className="box" data-aos="zoom-in-left">
                  <a href="/graphics-designing">
                    <div className="boximg">
                      <img src={Graphic1} alt="Graphics Designing" />
                    </div>
                    <div className="boxtxt">
                      <h3>
                        <strong>Graphics Designing</strong>
                      </h3>
                      <h4>
                        Graphics is so much more than just brand recognition. We
                        boast of an amazing team of designers who not only bring
                        your ideas to life but also engage and build a rapport
                        with your audience. Our tremendous focus is on creating
                        outstanding designs, striking logos, engaging flyers,
                        and attractive flexes amongst other graphical content.
                      </h4>
                    </div>
                  </a>
                </div>
              </div>
              {/* Custom Software Development */}
              <div className="col-lg-4">
                <div className="box" data-aos="zoom-in">
                  <a href="/custom-software-development">
                    <div className="boximg">
                      <img src={CSD1} alt="Custom Software Development" />
                    </div>
                    <div className="boxtxt">
                      <h3>
                        <strong>Custom Software Development</strong>
                      </h3>
                      <h4>
                        Our team experts ensure a secure and fully featured
                        custom software like ERP and CRM, CMS, business process
                        automation system, and many more for SMEs, Startups, and
                        businesses.
                      </h4>
                    </div>
                  </a>
                </div>
              </div>
              {/* SEO */}
              <div className="col-lg-4">
                <div className="box" data-aos="zoom-in-right">
                  <a href="/search-engine-optimization">
                    <div className="boximg">
                      <img src={SEO1} alt="Search Engine Optimization" />
                    </div>
                    <div className="boxtxt">
                      <h3>
                        <strong>Search Engine Optimization</strong>
                      </h3>
                      <h4>
                        Search Engine Optimization is a proven marketing
                        strategy. Our tried and true SEO techniques ensure that
                        your website ranks high in the search engines and wins
                        more traffic than you could ever hope for!
                      </h4>
                    </div>
                  </a>
                </div>
              </div>
              {/* Pay Per Click */}
              <div className="col-lg-4">
                <div className="box" data-aos="zoom-in-left">
                  <a href="/pay-per-click-ads">
                    <div className="boximg">
                      <img src={PPC1} alt="Pay Per Click Management" />
                    </div>
                    <div className="boxtxt">
                      <h3>
                        <strong>Pay Per Click Management</strong>
                      </h3>
                      <h4>
                        Pay Per Click Marketing is a great way to target an
                        audience. pixXmo will help you to target the right
                        audience for the right price at the right time to
                        generate more clicks.
                      </h4>
                    </div>
                  </a>
                </div>
              </div>
              {/* Mobile App Development */}
              <div className="col-lg-4">
                <div className="box" data-aos="zoom-in">
                  <a href="/mobile-application-development">
                    <div className="boximg">
                      <img src={App1} alt="Mobile Application Development" />
                    </div>
                    <div className="boxtxt">
                      <h3>
                        <strong>Mobile Application Development</strong>
                      </h3>
                      <h4>
                        What is quicker, easier, and more accessible is probably
                        an application. How many times do we hear someone saying
                        that there is an app for this? Applications that are
                        usually referred to as apps are taking the entire
                        industry and how we see and access things by storm. We
                        design, deploy and develop these apps at minimalist
                        prices with a focus on conveying what is yours.
                      </h4>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Ends */}

        {/* Why we are different */}
        <div className="one">
          <h1>
            <strong>Why we are different?</strong>
          </h1>
          <h4>
            At pixxmo, we understand how important it is for SMEs, businesses,
            startups like yours to be able to market themselves effectively
            online in order to succeed in today’s competitive marketplace. We
            have the knowledge and experience needed to help your business grow
            through all forms of digital media including SEO, social media
            marketing, PPC, affiliate marketing, content marketing, email
            marketing campaigns, and many more.
          </h4>
          <div className="row">
            <div className="col-lg-4">
              <span className="d-flex align-items-stretch ">
                <i className="fas fa-fire"></i>
                <h5>On-Time Delivery</h5>
              </span>
            </div>
            <div className="col-lg-4">
              <span className="d-flex align-items-stretch ">
                <i className="fas fa-fire"></i>
                <h5>We work as our business</h5>
              </span>
            </div>
            <div className="col-lg-4">
              <span className="d-flex align-items-stretch ">
                <i className="fas fa-fire"></i>
                <h5>Affordable</h5>
              </span>
            </div>
            <div className="col-lg-4">
              <span className="d-flex align-items-stretch ">
                <i className="fas fa-fire"></i>
                <h5>Transparent Reporting</h5>
              </span>
            </div>
            <div className="col-lg-4">
              <span className="d-flex align-items-stretch ">
                <i className="fas fa-fire"></i>
                <h5>Industry-level quality of service</h5>
              </span>
            </div>
            <div className="col-lg-4">
              <span className="d-flex align-items-stretch ">
                <i className="fas fa-fire"></i>
                <h5>
                  Guaranteed results even in highly competitive tasks such as
                  SEO
                </h5>
              </span>
            </div>
          </div>
        </div>
        {/* Ends */}

        {/* How we work */}
        <section id="how">
          <div className="how">
            <h1>
              <strong>How we work?</strong>
            </h1>
            <div className="howstep">
              <ul>
                <li className="d-flex align-items-stretch">
                  <span>
                    <i className="fab fa-searchengin"></i>
                  </span>
                  <h4>
                    <strong>Research</strong>
                    <br />
                    We understand our client requirement, that what they are
                    looking for
                  </h4>
                </li>
                <li className="d-flex align-items-stretch">
                  <span>
                    <i className="far fa-lightbulb"></i>
                  </span>
                  <h4>
                    <strong>Strategy</strong> <br />
                    We make a custom strategy for your business based on the
                    research and information we have gathered
                  </h4>
                </li>
                <li className="d-flex align-items-stretch">
                  <span>
                    <i className="fab fa-sketch"></i>
                  </span>
                  <h4>
                    <strong>Designing</strong> <br />
                    Once we acquire all the details we start wireframing,
                    sketching the design in the best possible way, measuring the
                    result, and making changes to complete the business
                    requirements.
                  </h4>
                </li>
                <li className="d-flex align-items-stretch">
                  <span>
                    <i className="fab fa-connectdevelop"></i>
                  </span>
                  <h4>
                    <strong>Development</strong> <br />
                    After the completion of the design of the project, our
                    development team starts working on schema and logic building
                    for the project and starts development.
                  </h4>
                </li>
                <li className="d-flex align-items-stretch">
                  <span>
                    <i className="fab fa-servicestack"></i>
                  </span>
                  <h4>
                    <strong>Testing</strong> <br />
                    Once the development is done, the testing team starts
                    testing the project at the top level to find all the bugs
                    and issues so that our development team can solve the issue
                    or bug before the final handover of the project.
                  </h4>
                </li>
                <li className="d-flex align-items-stretch">
                  <span>
                    <i className="far fa-check-circle"></i>
                  </span>
                  <h4>
                    <strong>Delivery</strong> <br />
                    Once the testing is done and all the bugs and issues fixed
                    then we serve the demo to our client and then deliver the
                    project with the detailed report.
                  </h4>
                </li>
              </ul>
            </div>
          </div>
        </section>
        {/* Ends */}

        {/* Industry Section */}
        <section id="industry">
          <div className="industry text-center">
            <h1>
              <strong>Industries We Serve</strong>
            </h1>
            <h4>We help grow with our Services.</h4>
            <div className="row">
              <div className="col-lg-3">
                <div className="inbox" data-aos="zoom-in-right">
                  <div className="inboximg">
                    <i className="fas fa-school"></i>
                  </div>
                  <div className="inboxtxt">
                    <h4>Education</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="inbox" data-aos="zoom-in">
                  <div className="inboximg">
                    <i className="fas fa-heartbeat"></i>
                  </div>
                  <div className="inboxtxt">
                    <h4>Healthcare</h4>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-3">
                <div className="inbox" data-aos="zoom-in">
                  <div className="inboximg">
                    <i className="fas fa-rocket"></i>
                  </div>
                  <div className="inboxtxt">
                    <h4>Research</h4>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-3">
                <div className="inbox" data-aos="zoom-in-left">
                  <div className="inboximg">
                    <i className="fas fa-store-alt"></i>
                  </div>
                  <div className="inboxtxt">
                    <h4>Businesses</h4>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-3">
                <div className="inbox" data-aos="zoom-in-right">
                  <div className="inboximg">
                    <i className="fas fa-cogs"></i>
                  </div>
                  <div className="inboxtxt">
                    <h4>Utilities</h4>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-3">
                <div className="inbox" data-aos="zoom-in">
                  <div className="inboximg">
                    <i className="fas fa-city"></i>
                  </div>
                  <div className="inboxtxt">
                    <h4>Real Estate</h4>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-3">
                <div className="inbox" data-aos="zoom-in">
                  <div className="inboximg">
                    <i className="fas fa-industry"></i>
                  </div>
                  <div className="inboxtxt">
                    <h4>Corporate</h4>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-3">
                <div className="inbox" data-aos="zoom-in-left">
                  <div className="inboximg">
                    <i className="fas fa-coins"></i>
                  </div>
                  <div className="inboxtxt">
                    <h4>Financial</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="inbox" data-aos="zoom-in-right">
                  <div className="inboximg">
                    <i className="fas fa-briefcase"></i>
                  </div>
                  <div className="inboxtxt">
                    <h4>Job Portal</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="inbox" data-aos="zoom-in">
                  <div className="inboximg">
                    <i className="fas fa-utensils"></i>
                  </div>
                  <div className="inboxtxt">
                    <h4>Hotels/ Restaurant</h4>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-3">
                <div className="inbox" data-aos="zoom-in">
                  <div className="inboximg">
                    <i className="fas fa-hotel"></i>
                  </div>
                  <div className="inboxtxt">
                    <h4>Hotel</h4>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-lg-3">
                <div className="inbox" data-aos="zoom-in-left">
                  <div className="inboximg">
                    <i className="fas fa-link"></i>
                  </div>
                  <div className="inboxtxt">
                    <h4>Social Network</h4>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-lg-3">
                <div className="inbox" data-aos="zoom-in-left">
                  <div className="inboximg">
                    <i className="fas fa-music"></i>
                  </div>
                  <div className="inboxtxt">
                    <h4>Music</h4>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-3">
                <div className="inbox" data-aos="zoom-in-left">
                  <div className="inboximg">
                    <i className="fas fa-plane-departure"></i>
                  </div>
                  <div className="inboxtxt">
                    <h4>Travel</h4>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-3">
                <div className="inbox" data-aos="zoom-in-left">
                  <div className="inboximg">
                    <i className="fas fa-warehouse"></i>
                  </div>
                  <div className="inboxtxt">
                    <h4>Inventory</h4>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-lg-3">
                <div className="inbox" data-aos="zoom-in-left">
                  <div className="inboximg">
                    <i className="fas fa-dolly"></i>
                  </div>
                  <div className="inboxtxt">
                    <h4>Retail</h4>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        {/* Ends */}

        {/* What makes our services different */}
        <section id="diff">
          <div className="diff">
            <h1>
              <strong>What makes our services different?</strong>
            </h1>
            <div className="row">
              <div className="col-lg-6">
                <div className="inbox" data-aos="zoom-in-right">
                  <div className="inboxtxt">
                    <h4>Creativity</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="inbox" data-aos="zoom-in-right">
                  <div className="inboxtxt">
                    <h4>Uniqueness</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="inbox" data-aos="zoom-in-right">
                  <div className="inboxtxt">
                    <h4>Innovation</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="inbox" data-aos="zoom-in-right">
                  <div className="inboxtxt">
                    <h4>Patience</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Ends */}

        {/* Client Section */}
        <section id="client">
          <div className="client">
            <h1>
              <strong>Our Clients</strong>
            </h1>
            <div className="marquee">
              <div className="track">
                <div className="clientlogo">
                  <ul>
                    <li>
                      <img src={x4us} alt="" />
                    </li>
                    <li>
                      <img src={Briller} alt="" />
                    </li>
                    <li>
                      <img src={FrontFace} alt="" />
                    </li>
                    <li>
                      <img src={GoCarMats} alt="" />
                    </li>
                    <li>
                      <img src={Hans} alt="" />
                    </li>
                    <li>
                      <img src={JK} alt="" />
                    </li>
                    <li>
                      <img src={Sparks} alt="" />
                    </li>
                    <li>
                      <img src={Verge} alt="" />
                    </li>
                    <li>
                      <img src={Weihz} alt="" />
                    </li>
                    <li>
                      <img src={OldMonk} alt="" />
                    </li>
                    <li>
                      <img src={ThepureEarth} alt="" />
                    </li>
                    <li>
                      <img src={FrontFace} alt="" />
                    </li>
                    <li>
                      <img src={GoCarMats} alt="" />
                    </li>
                    <li>
                      <img src={Hans} alt="" />
                    </li>
                    <li>
                      <img src={JK} alt="" />
                    </li>
                    <li>
                      <img src={Sparks} alt="" />
                    </li>
                    <li>
                      <img src={Verge} alt="" />
                    </li>
                    <li>
                      <img src={Weihz} alt="" />
                    </li>
                    <li>
                      <img src={OldMonk} alt="" />
                    </li>
                    <li>
                      <img src={ThepureEarth} alt="" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Ends */}

        {/* Benefits of working with us */}
        <section id="beni">
          <div className="beni">
            <h1>
              <strong>Benefits of Working with us</strong>
            </h1>
            <div className="row">
              <div className="col-lg-3">
                <div className="inbox" data-aos="zoom-in-right">
                  <div className="inboxtxt">
                    <p>Competitive Cost</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="inbox" data-aos="zoom-in-right">
                  <div className="inboxtxt">
                    <p>Time Limites</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="inbox" data-aos="zoom-in-right">
                  <div className="inboxtxt">
                    <p>Product Orientation</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="inbox" data-aos="zoom-in-right">
                  <div className="inboxtxt">
                    <p>Our Experienced Team</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="inbox" data-aos="zoom-in-right">
                  <div className="inboxtxt">
                    <p>Project Design, Analysis, Planning, and Strategy</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="inbox" data-aos="zoom-in-right">
                  <div className="inboxtxt">
                    <p>Our Customer Service</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="inbox" data-aos="zoom-in-right">
                  <div className="inboxtxt">
                    <p>Result Oriented</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="inbox" data-aos="zoom-in-right">
                  <div className="inboxtxt">
                    <p>One-Stop Destination</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Ends */}

        {/* Our Certifications */}
        <section id="certi">
          <div className="certi">
            <h1>
              <strong>Our Certifications</strong>
            </h1>
            <div className="row">
              <div className="col-lg-12">
                <div className="certiimg" data-aos="zoom-in-right">
                  <a
                    href="https://learninglab.about.ads.microsoft.com/certification/membership-profile/id/378334/nitesh-nagpal/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={MACP}
                      alt="Microsoft Advertising Certified Professional"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Ends */}

        {/* Contact Section */}
        <section id="contact">
          <div className="contacthome">
            <div className="row">
              <div className="col-lg-6">
                <div className="contacttxt">
                  <h1>Have any projects in your mind?</h1>
                  <h4>LET'S TALK AND GET STARTED</h4>
                  <div className="d-flex align-items-stretch contdetails">
                    <span>
                      <i className="fas fa-envelope"></i>
                    </span>
                    <h3>
                      MAIL TO OUR HR DEPARTMENT
                      <br />
                      <a href="mailto:contact@pixxmo.com">
                        <strong>contact@pixxmo.com</strong>
                      </a>
                    </h3>
                  </div>
                  <div className="d-flex align-items-stretch contdetails">
                    <span>
                      <i className="fas fa-phone-alt"></i>
                    </span>
                    <h3>
                      OUR CONTACT NUMBER
                      <br />
                      <a href="tel:9588151671">
                        <strong>+91-9588151671</strong>
                      </a>
                    </h3>
                  </div>
                  <h2>& What's you will get:</h2>
                  <ul>
                    <li className="d-flex align-items-stretch ">
                      <i className="fas fa-fire"></i> Excellent Customer Support
                    </li>
                    <li className="d-flex align-items-stretch ">
                      <i className="fas fa-fire"></i> Project Consulting by
                      Experts
                    </li>
                    <li className="d-flex align-items-stretch ">
                      <i className="fas fa-fire"></i> On-Time Project Delivery
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <Contact />
              </div>
            </div>
          </div>
        </section>
        {/* Ends */}
      </div>
    </div>
  );
};

export default Home;
