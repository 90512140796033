import React from "react";
import { Helmet } from "react-helmet";

import "./service.css";

import Contact from "../../components/contact/Contact";

import Service1 from "../../images/assets/icons/Custom_Dev.png";

const Custom = () => {
  return (
    <div>
      <Helmet>
        <title>pixXmo | Custom Software Development</title>
      </Helmet>
      <div className="seo">
        <h1>
          <strong>Custom Software Development</strong>
        </h1>
        <h3>
          Our team experts ensure a secure and fully featured custom software
          like ERP and CRM, CMS, business process automation system, and many
          more for SMEs, Startups, and businesses.
        </h3>
        <h2>
          <strong>Services we offer</strong>
        </h2>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>
            <strong>Corporate Software Systems:</strong> We build software that
            automates enterprise-wide processes and covers interdepartmental
            information flows. Example: ERP, HRM, operations management
            software.
          </h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>
            <strong>Departmental Software:</strong> We build software with
            features that cover the needs of a particular department within your
            organization. Example: CRM, financial management software, inventory
            management software.
          </h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>
            <strong>Customer Self-Service Apps:</strong> We build applications
            that help your organization deliver tailored customer experiences to
            win the market competition. Example: mobile banking apps, patient
            portals, shopping apps.
          </h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>
            <strong>Software for specific business functions:</strong> We build
            software that fully covers business-unique and industry-specific
            functions, thus it doesn’t need time-consuming and complicated
            customization as is the case with market-available software
            products. Example: price management software, clinical decision
            support app, invoicing, and billing software.
          </h4>
        </span>
        <hr />
        <h2>
          <strong>Benefits</strong>
        </h2>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>Increase the level of productivity and efficiency</h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>
            Easily integrates with existing legacy systems or applications
          </h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>More secure against external threats</h4>
        </span>
        <hr />
        <h2>
          <strong>Why did you choose us?</strong>
        </h2>
        <h5>
          Pixxmo helps businesses to build, manage, and modernize applications
          using advanced technologies.
        </h5>
        <h5>
          We implement solutions covering all the stages of the software
          development cycle:
        </h5>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>Project Planning, Monitoring</h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>
            Identification and management of requirements and the impact on the
            process
          </h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>Software development</h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>Testing, planning, and execution</h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>Training, planning, and execution</h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>Solution Implementation</h4>
        </span>
        <hr />
        <h2>
          <strong>Tools we use</strong>
        </h2>
        <h5>
          Our products are built on top of modern frameworks and technologies
        </h5>
        <div className="row">
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Python</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Java</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Node.js</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>PHP</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>GO</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>HTML5</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>CSS3</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>JavaScript</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Angular</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>JQuery</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>React Js</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Meteor</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Vue.js</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Flutter</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>PhoneGap</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Cordova</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Progressive Web Apps</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>C++</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Qt</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Swift</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>SQL Server</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>MongoDB</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>PostgreSQL</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Oracle</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Amazon Web Services</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Amazon RDS</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Amazon DynamoDB</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Google Cloud SQL</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>PowerBI</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Magento</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Salesforce</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>AWS Developer Tools</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Azure DevOps</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Jenkins</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Elastic Search</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Terraform</h4>
            </div>
          </div>
        </div>
        <hr />
      </div>
      {/* Request a Quote Section */}
      <div className="servicecontact">
        <h1>
          How we can help{" "}
          <span>
            <strong>your business grow.</strong>
          </span>
        </h1>
        <div className="row">
          <div className="col-lg-6">
            <div className="serviceimg">
              <img src={Service1} alt="Our Services" />
            </div>
          </div>
          <div className="col-lg-6 servicecnt">
            <Contact />
          </div>
        </div>
      </div>
      {/* Ends */}
    </div>
  );
};

export default Custom;
