import React from "react";
import { Helmet } from "react-helmet";

import "./service.css";

import Contact from "../../components/contact/Contact";

import Service1 from "../../images/assets/icons/Website.png";

const Website = () => {
  return (
    <div>
      <Helmet>
        <title>pixXmo | Website Design and Development</title>
      </Helmet>
      <div className="seo">
        <h1>
          <strong>Website Design and Development</strong>
        </h1>
        <h3>
          Having designed, developed, and managed a multitude of business
          websites or applications on all the popular platforms for a variety of
          industries, We have vast experience in delivering quality outcomes for
          all your needs. Your business website or application is a better
          illustration of your business and it should be a great blend of every
          ingredient which is essential to ensure its performance. Consult the
          experts at ‘pixXmo’ to get the best-quality business website or
          application that not only perform well on distinct browsers but also
          support your digital marketing efforts.
        </h3>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>
            <strong>Website Design and Development</strong>
          </h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>
            <strong>E-Commerce Website Design and Development</strong>
          </h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>
            <strong>WordPress</strong>
          </h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>
            <strong>Landing Page</strong>
          </h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>
            <strong>Corporate Website Design and Development</strong>
          </h4>
        </span>
        <h2>
          <strong>Why do you need Professional Website?</strong>
        </h2>
        <h5>
          You should have a professional website for your business is to :
        </h5>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>
            <strong>Increase your organization’s credibility</strong>
          </h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>
            <strong>Showcasing your brand to your prospective customers</strong>
          </h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>
            <strong>Increase your chances of getting leads.</strong>
          </h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>
            <strong>Increase your customer base</strong>
          </h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>
            <strong>Provides an all-around better user experience</strong>
          </h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>
            <strong>
              Target the most qualified customers and get the best ROI on your
              ad spend.
            </strong>
          </h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>
            <strong>Ensures your success over the long term</strong>
          </h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>
            <strong>
              Strategically target your customers through a website
            </strong>
          </h4>
        </span>
        <hr />
        <h2>
          <strong>
            Grow your business with top quality website development services
          </strong>
        </h2>
        <div className="row">
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Create your web identity</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Improve revenue</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Increase your market</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Easy to change and update</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Be accessible to new customers</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Be found to your customers</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Reduce offline advertising cost</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Spread your local reach</h4>
            </div>
          </div>
        </div>
        <hr />
        <h2>
          <strong>How can pixxmo help with website development?</strong>
        </h2>
        <h5>
          pixXmo provides reliable and best website design and development which
          help your business to grow
        </h5>
        <div className="row">
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Increased website traffic</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Responsive Website Design</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Needs Less Maintenance</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Faster Web Pages</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Less Bounce Rate</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Higher Conversion Rates</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Improved SEO</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Good Branding</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Improved Online and Offline Browsing Experience</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Easier Analytics Reporting</h4>
            </div>
          </div>
        </div>
        <hr />
        <h2>
          <strong>Why did you choose us?</strong>
        </h2>
        <h5>
          pixXmo is committed to their client and offers great services in the
          design, development, programming, and marketing field. We put the
          effort into completing projects. We always make sure to serve our
          clients with the best solution possible that suits their business and
          provide advice at a reasonable cost. We always complete a project in
          such a way that is easy to use, manage, and scaleable.
        </h5>
        <div className="row">
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Exceptional Customer Service</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>High-Performance Web Solutions</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>ECommerce Specialists</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Digital Marketing Specialists</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Competitive Prices</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Multi-Channel ECommerce Solutions</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Responsive Ecommerce</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Extensive Experience</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Proven Track Record</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Dedicated Project Managers</h4>
            </div>
          </div>
        </div>
        <hr />
        <h2>
          <strong>
            Platforms we use to deliver the best website development services
          </strong>
        </h2>
        <div className="row">
          <div className="col-lg-4">
            <div className="seobox">
              <h4>WordPress</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Wix</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Shopify</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Weebly</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Magento</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Joomla</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Squarespace</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Duda</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Square Online</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Network Solutions</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>BigCommerce</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Site123</h4>
            </div>
          </div>
        </div>
        <hr />
        <h2>
          <strong>Technologies we use</strong>
        </h2>
        <h5>
          Our products are built on top of modern frameworks and technologies
        </h5>
        <div className="row">
          <div className="col-lg-4">
            <div className="seobox">
              <h4>HTML5</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>CSS3</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>JavaScript</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Angular</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>ReactJs</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Django/ Flask</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Sass</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Bootstrap</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>CodeIgnitor</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Joomla</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Cakephp</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Laravel</h4>
            </div>
          </div>
        </div>
        <hr />
      </div>
      {/* Request a Quote Section */}
      <div className="servicecontact">
        <h1>
          How we can help{" "}
          <span>
            <strong>your business grow.</strong>
          </span>
        </h1>
        <div className="row">
          <div className="col-lg-6">
            <div className="serviceimg">
              <img src={Service1} alt="Our Services" />
            </div>
          </div>
          <div className="col-lg-6 servicecnt">
            <Contact />
          </div>
        </div>
      </div>
      {/* Ends */}
    </div>
  );
};

export default Website;
