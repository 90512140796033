import React from "react";
import { Routes, Route } from "react-router";

// import bgVideo from "./images/bgdots.mp4";
import bgVideo from "./images/bgdots.gif";

// Components
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";

// Screens
import Home from "./screen/Home/Home";
import About from "./screen/About/About";
import ContactUs from "./screen/Contact/Contact";
import Portfolio from "./screen/Portfolio/Portfolio";
import Services from "./screen/Services/Services";

import Error from "./screen/Error/Error";

// import Pricing from "./screen/Pricing/Pricing";

import Faq from "./screen/Extra/Faq";
import Privacy from "./screen/Extra/Privacy";
import Terms from "./screen/Extra/Terms";

import Application from "./screen/Service/Application";
import Graphics from "./screen/Service/Graphics";
import PPC from "./screen/Service/PPC";
import SEO from "./screen/Service/SEO";
import SMM from "./screen/Service/SMM";
import Website from "./screen/Service/Website";
import Custom from "./screen/Service/Custom";
import Sitemap from "./screen/Sitemap/Sitemap";

function App() {
  return (
    <div>
      {/* background */}
      <div className="overlay"></div>
      <div className="bgvideo">
        {/* <video autoPlay loop muted preload="auto">
          <source src={bgVideo} />
        </video> */}{" "}
        <img src={bgVideo} alt="bg" />
      </div>
      {/* ends */}
      {/* main content */}
      <div className="wrapper">
        <div className="content">
          <Navbar />
          <div className="container-fluid">
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/our-services" element={<Services />} />
              <Route exact path="/portfolio" element={<Portfolio />} />
              <Route exact path="/sitemap" element={<Sitemap />} />
              {/* <Route exact path="/pricing" element={<Pricing />} /> */}
              <Route exact path="/aboutus" element={<About />} />
              <Route exact path="/contactus" element={<ContactUs />} />
              <Route exact path="*" element={<Error />} />
              <Route exact path="/faq" element={<Faq />} />
              <Route exact path="/privacy-policy" element={<Privacy />} />
              <Route exact path="/terms-and-conditions" element={<Terms />} />

              {/* Our Services */}
              <Route
                exact
                path="/mobile-application-development"
                element={<Application />}
              />
              <Route exact path="/graphics-designing" element={<Graphics />} />
              <Route exact path="/pay-per-click-ads" element={<PPC />} />
              <Route
                exact
                path="/search-engine-optimization"
                element={<SEO />}
              />
              <Route exact path="/social-media-marketing" element={<SMM />} />
              <Route
                exact
                path="/website-design-and-development"
                element={<Website />}
              />
              <Route
                exact
                path="/custom-software-development"
                element={<Custom />}
              />
            </Routes>
          </div>
        </div>
        <div className="footer">
          <Footer />
        </div>
      </div>
      {/* ends */}
    </div>
  );
}

export default App;
