import React from "react";
import { Helmet } from "react-helmet";

import "./extra.css";

const Privacy = () => {
  return (
    <div>
      <Helmet>
        <title>pixXmo | Privacy Policy</title>
      </Helmet>
      <div className="extra">
        <h1>Privacy Policy</h1>
        <h5>Last updated: February 11, 2022</h5>
        <h4>
          pixXmo Online Services Pvt Ltd and affiliates ("company", "we", "us",
          or "our"), are committed to protecting and preserving your personal
          information and your right to privacy.
        </h4>
        <h4>
          When you visit our website pixxmo.com and use our services, you
          provide us with your personal information. In this privacy notice, we
          describe our privacy policy. We explain to you what information we
          collect, how we use it, and what rights you have in relation to it. It
          is important, please take your time to read this. If there are any
          terms in this privacy policy that you do not agree with, please don’t
          use our sites and services.
        </h4>
        <h4>
          This privacy policy applies to all information collected through our
          website (<a href="/">https://www.pixxmo.com</a>) and any related
          services, sales, marketing or events (the "Services"). By using the
          Sites or Services, you consent to the collection and use of
          information in accordance with this privacy policy.
        </h4>
        <h4>
          <strong>
            Please read this privacy policy carefully as it will help you make
            informed decisions about sharing your personal information with us.
          </strong>
        </h4>
        <br />
        <h2>
          <strong>Collecting and Using Your Personal Data</strong>
        </h2>
        <hr />
        <h4>
          <strong>Types of Data Collected</strong>
        </h4>
        <h5>
          <strong>Personal Data</strong>
        </h5>
        <h4>
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally, identifiable information may include, but is
          not limited to:
        </h4>
        <ul>
          <li className="d-flex align-items-stretch">
            <i className="fas fa-fire"></i>
            <h4>Email Address</h4>
          </li>
          <li className="d-flex align-items-stretch">
            <i className="fas fa-fire"></i>
            <h4>First Name and Last Name</h4>
          </li>
          <li className="d-flex align-items-stretch">
            <i className="fas fa-fire"></i>
            <h4>Phone Number</h4>
          </li>
          <li className="d-flex align-items-stretch">
            <i className="fas fa-fire"></i>
            <h4>Usage Data</h4>
          </li>
        </ul>
        <h5>
          <strong>Usage Data</strong>
        </h5>
        <h4>Usage Data has collected automatically when using the Service.</h4>
        <h4>
          Usage Data may include information such as Your Device's Internet
          Protocol address (e.g. IP address), browser type, browser version, the
          pages of our Service that You visit, the time and date of Your visit,
          the time spent on those pages, unique device identifiers and other
          diagnostic data.
        </h4>
        <h4>
          When You access the Service by or through a mobile device, We may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, Your mobile device unique ID,
          the IP address of Your mobile device, Your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </h4>
        <h4>
          We may also collect information that Your browser sends whenever You
          visit our Service or when You access the Service by or through a
          mobile device.
        </h4>
        <h5>
          <strong>Tracking Technologies and Cookies</strong>
        </h5>
        <h4>
          We use cookies and similar tracking technologies to track the activity
          on Our Service and store certain information. Tracking technologies
          used are beacons, tags, and scripts to collect and track information
          and to improve and analyze Our Service. The technologies We use may
          include:
        </h4>
        <ul>
          <li className="d-flex align-items-stretch">
            <i className="fas fa-fire"></i>
            <h4>
              <strong>Cookies or Browser Cookies.</strong> A cookie is a small
              file placed on Your Device. You can instruct Your browser to
              refuse all Cookies or to indicate when a cookie is being sent.
              However, if You do not accept Cookies, You may not be able to use
              some parts of our Service. Unless you have adjusted Your browser
              setting so that it will refuse cookies, our Service may use
              Cookies.
            </h4>
          </li>
          <li className="d-flex align-items-stretch">
            <i className="fas fa-fire"></i>
            <h4>
              <strong>Web Beacons.</strong> Certain sections of our Service and
              our emails may contain small electronic files known as web beacons
              (also referred to as clear gifs, pixel tags, and single-pixel
              gifs) that permit the Company, for example, to count users who
              have visited those pages or opened an email and for other related
              website statistics (for example, recording the popularity of a
              certain section and verifying system and server integrity).
            </h4>
          </li>
        </ul>
        <h4>
          Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
          remain on Your personal computer or mobile device when You go offline,
          while Session Cookies are deleted as soon as You close Your web
          browser. You can learn more about cookies here: Cookies by TermsFeed
          Generator.
        </h4>
        <h4>
          We use both Session and Persistent Cookies for the purposes set out
          below:
        </h4>
        <ul>
          <li className="d-flex align-items-stretch">
            <i className="fas fa-fire"></i>
            <h4>
              <strong>Necessary / Essential Cookies</strong>
              <br />
              Type: Session Cookies <br />
              Administered by: Us <br />
              Purpose: These cookies are essential to provide You with services
              available through the Website and to enable You to use some of its
              features. They help to authenticate users and prevent fraudulent
              use of user accounts. Without these Cookies, the services that You
              have asked for cannot be provided, and We only use these Cookies
              to provide You with those services.
            </h4>
          </li>
          <li className="d-flex align-items-stretch">
            <i className="fas fa-fire"></i>
            <h4>
              <strong>Cookies Policy / Notice Acceptance Cookies</strong>
              <br />
              Type: Persistent Cookies <br />
              Administered by: Us <br />
              Purpose: These Cookies identify if users have accepted the use of
              cookies on the Website.
            </h4>
          </li>
          <li className="d-flex align-items-stretch">
            <i className="fas fa-fire"></i>
            <h4>
              <strong>Functionality Cookies</strong> <br />
              Type: Persistent Cookies <br />
              Administered by: Us <br />
              Purpose: These cookies allow us to remember choices You make when
              You use the Website, such as remembering your login details or
              language preference. The purpose of these cookies is to provide
              You with a more personal experience and to avoid You having to
              re-enter your preferences every time You use the Website.
            </h4>
          </li>
        </ul>
        <h4>
          For more information about the cookies we use and your choices
          regarding cookies, please visit our Cookies Policy or the Cookies
          section of our Privacy Policy.
        </h4>
        <h5>
          <strong>Use of Your Personal Data</strong>
        </h5>
        <h4>The Company may use Personal Data for the following purposes:</h4>
        <ul>
          <li className="d-flex align-items-stretch">
            <i className="fas fa-fire"></i>
            <h4>
              <strong>To provide and maintain our Service</strong>, including to
              monitor the usage of our Service.
            </h4>
          </li>
          <li className="d-flex align-items-stretch">
            <i className="fas fa-fire"></i>
            <h4>
              <strong>To manage Your Account:</strong> to manage Your
              registration as a user of the Service. The Personal Data You
              provide can give You access to different functionalities of the
              Service that are available to You as a registered user.
            </h4>
          </li>
          <li className="d-flex align-items-stretch">
            <i className="fas fa-fire"></i>
            <h4>
              <strong>For the performance of a contract:</strong> the
              development, compliance, and undertaking of the purchase contract
              for the products, items, or services You have purchased or of any
              other contract with Us through the Service.
            </h4>
          </li>
          <li className="d-flex align-items-stretch">
            <i className="fas fa-fire"></i>
            <h4>
              <strong>To contact You:</strong> To contact You by email,
              telephone calls, SMS, or other equivalent forms of electronic
              communication, such as a mobile application's push notifications
              regarding updates or informative communications related to the
              functionalities, products, or contracted services, including the
              security updates, when necessary or reasonable for their
              implementation.
            </h4>
          </li>
          <li className="d-flex align-items-stretch">
            <i className="fas fa-fire"></i>
            <h4>
              <strong>To provide You</strong> with news, special offers, and
              general information about other goods, services, and events that
              we offer that are similar to those that you have already purchased
              or enquired about unless You have opted not to receive such
              information.
            </h4>
          </li>
          <li className="d-flex align-items-stretch">
            <i className="fas fa-fire"></i>
            <h4>
              <strong>To manage Your requests:</strong> To attend and manage
              Your requests to Us.
            </h4>
          </li>
          <li className="d-flex align-items-stretch">
            <i className="fas fa-fire"></i>
            <h4>
              <strong>For business transfers:</strong> We may use Your
              information to evaluate or conduct a merger, divestiture,
              restructuring, reorganization, dissolution, or another sale or
              transfer of some or all of Our assets, whether as a going concern
              or as part of bankruptcy, liquidation, or similar proceeding, in
              which Personal Data held by Us about our Service users is among
              the assets transferred.
            </h4>
          </li>
          <li className="d-flex align-items-stretch">
            <i className="fas fa-fire"></i>
            <h4>
              <strong>For other purposes:</strong> We may use Your information
              for other purposes, such as data analysis, identifying usage
              trends, determining the effectiveness of our promotional
              campaigns, and evaluating and improving our Service, products,
              services, marketing, and your experience.
            </h4>
          </li>
        </ul>
        <h4>
          We may share Your personal information in the following situations:
        </h4>
        <ul>
          <li className="d-flex align-items-stretch">
            <i className="fas fa-fire"></i>
            <h4>
              <strong>With Service Providers:</strong> We may share Your
              personal information with Service Providers to monitor and analyze
              the use of our Service, to contact You.
            </h4>
          </li>
          <li className="d-flex align-items-stretch">
            <i className="fas fa-fire"></i>
            <h4>
              <strong>For business transfers:</strong> We may share or transfer
              Your personal information in connection with, or during
              negotiations of, any merger, sale of company assets, financing, or
              acquisition of all or a portion of Our business to another
              company.
            </h4>
          </li>
          <li className="d-flex align-items-stretch">
            <i className="fas fa-fire"></i>
            <h4>
              <strong>With Affiliates:</strong> We may share Your information
              with Our affiliates, in which case we will require those
              affiliates to honor this Privacy Policy. Affiliates include Our
              parent company and any other subsidiaries, joint venture partners,
              or other companies that We control or that are under common
              control with Us.
            </h4>
          </li>
          <li className="d-flex align-items-stretch">
            <i className="fas fa-fire"></i>
            <h4>
              <strong>With business partners:</strong> We may share Your
              information with Our business partners to offer You certain
              products, services, or promotions.
            </h4>
          </li>
          <li className="d-flex align-items-stretch">
            <i className="fas fa-fire"></i>
            <h4>
              <strong>With other users:</strong> when You share personal
              information or otherwise interact in the public areas with other
              users, such information may be viewed by all users and may be
              publicly distributed outside.
            </h4>
          </li>
          <li className="d-flex align-items-stretch">
            <i className="fas fa-fire"></i>
            <h4>
              <strong>With Your Consent:</strong> We may disclose Your personal
              information for any other purpose with Your consent.
            </h4>
          </li>
        </ul>
        <h5>
          <strong>Retention of Your Personal Data</strong>
        </h5>
        <h4>
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </h4>
        <h4>
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of Our Service, or We are legally obligated
          to retain this data for longer time periods.
        </h4>
        <h5>
          <strong>Transfer of Your Personal Data</strong>
        </h5>
        <h4>
          Your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside
          of Your state, province, country, or other governmental jurisdiction
          where the data protection laws may differ from those from Your
          jurisdiction.
        </h4>
        <h4>
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
        </h4>
        <h4>
          The Company will take all steps reasonably necessary to ensure that
          Your data is treated securely and in accordance with this Privacy
          Policy and no transfer of Your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </h4>
        <h4>
          <strong>Disclousre of Your Personal Data</strong>
        </h4>
        <h5>
          <strong>Business Transactions</strong>
        </h5>
        <h4>
          If the Company is involved in a merger, acquisition, or asset sale,
          Your Personal Data may be transferred. We will provide notice before
          Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </h4>
        <h5>
          <strong>Law Enforcement</strong>
        </h5>
        <h4>
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </h4>
        <h5>
          <strong>Other Legal Requirements</strong>
        </h5>
        <h4>
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
        </h4>
        <ul>
          <li className="d-flex align-items-stretch">
            <i className="fas fa-fire"></i>
            <h4>Comply with a legal obligation</h4>
          </li>
          <li className="d-flex align-items-stretch">
            <i className="fas fa-fire"></i>
            <h4>Protect and defend the rights or property of the Company</h4>
          </li>
          <li className="d-flex align-items-stretch">
            <i className="fas fa-fire"></i>
            <h4>
              Prevent or investigate possible wrongdoing in connection with the
              Service
            </h4>
          </li>
          <li className="d-flex align-items-stretch">
            <i className="fas fa-fire"></i>
            <h4>
              Protect the personal safety of users of the Service or the public
            </h4>
          </li>
          <li className="d-flex align-items-stretch">
            <i className="fas fa-fire"></i>
            <h4>Protect against legal liability</h4>
          </li>
        </ul>
        <h5>
          <strong>Security of Your Personal Data</strong>
        </h5>
        <h4>
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </h4>
        <br />
        <h2>
          <strong>Links to Other Websites</strong>
        </h2>
        <hr />
        <h4>
          Our Service may contain links to other websites that are not operated
          by Us. If You click on a third-party link, You will be directed to
          that third party's site. We strongly advise You to review the Privacy
          Policy of every site You visit.
        </h4>
        <h4>
          We have no control over and assume no responsibility for the content,
          privacy policies, or practices of any third-party sites or services.
        </h4>
        <br />
        <h2>
          <strong>Updates to this Privacy Policy</strong>
        </h2>
        <hr />
        <h4>
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page. You are
          advised to review this Privacy Policy periodically for any changes.
        </h4>
        <h4>
          We will let You know via email and/or a prominent notice on Our
          Service, prior to the change becoming effective and update the "Last
          Updated" date at the top of this Privacy Policy.
        </h4>
        <h4>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </h4>
        <br />
        <h2>
          <strong>Contact Us</strong>
        </h2>
        <hr />
        <h4>
          For any queries, comments, or clarifications about this policy, email
          us at{" "}
          <strong>
            <a href="mailto:contact@pixxmo.com">contact@pixxmo.com</a>
          </strong>{" "}
          or by post to:
        </h4>
        <span>
          <h4>
            <strong>pixxmo Online Services Private Limited</strong>
          </h4>
          <h4>
            <strong>
              977, Sector-1, Near Sector-1 Main Market, Rohtak, Haryana -
              124001, India
            </strong>
          </h4>
        </span>
      </div>
    </div>
  );
};

export default Privacy;
