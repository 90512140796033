import React from "react";
import Helmet from "react-helmet";

import "./sitemap.css";

const Sitemap = () => {
  return (
    <div>
      <Helmet>
        <title>pixXmo | Sitemap</title>
      </Helmet>
      <div className="sitemap">
        <h1>
          <strong>Sitemap</strong>
        </h1>
        <div className="row">
          <div className="col-lg-4">
            <div className="sitebox">
              <h2>
                <strong>Our Services</strong>
              </h2>
              <ul>
                <li className="d-flex align-items-stretch">
                  <i className="fas fa-fire"></i>
                  <h3>
                    <a href="/search-engine-optimization">
                      Search Engine Optimization
                    </a>
                  </h3>
                </li>
                <li className="d-flex align-items-stretch">
                  <i className="fas fa-fire"></i>
                  <h3>
                    <a href="/social-media-marketing">Social Media Marketing</a>
                  </h3>
                </li>
                <li className="d-flex align-items-stretch">
                  <i className="fas fa-fire"></i>
                  <h3>
                    <a href="/pay-per-click-ads">Pay Per Click Advertising</a>
                  </h3>
                </li>
                <li className="d-flex align-items-stretch">
                  <i className="fas fa-fire"></i>
                  <h3>
                    <a href="/graphics-designing">Graphics Designing</a>
                  </h3>
                </li>
                <li className="d-flex align-items-stretch">
                  <i className="fas fa-fire"></i>
                  <h3>
                    <a href="/website-design-and-development">
                      Website Design and Development
                    </a>
                  </h3>
                </li>
                <li className="d-flex align-items-stretch">
                  <i className="fas fa-fire"></i>
                  <h3>
                    <a href="/mobile-application-development">
                      Mobile Application Development
                    </a>
                  </h3>
                </li>
                <li className="d-flex align-items-stretch">
                  <i className="fas fa-fire"></i>
                  <h3>
                    <a href="/custom-software-development">
                      Custom Software Development
                    </a>
                  </h3>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="sitebox">
              <h2>
                <strong>Useful Links</strong>
              </h2>
              <ul>
                <li className="d-flex align-items-stretch">
                  <i className="fas fa-fire"></i>
                  <h3>
                    <a href="/aboutus">About Us</a>
                  </h3>
                </li>
                <li className="d-flex align-items-stretch">
                  <i className="fas fa-fire"></i>
                  <h3>
                    <a href="/our-services">Our Services</a>
                  </h3>
                </li>
                <li className="d-flex align-items-stretch">
                  <i className="fas fa-fire"></i>
                  <h3>
                    <a href="/portfolio">Portfolio</a>
                  </h3>
                </li>
                <li className="d-flex align-items-stretch">
                  <i className="fas fa-fire"></i>
                  <h3>
                    <a href="/faq">Frequently Asked Questions</a>
                  </h3>
                </li>
                <li className="d-flex align-items-stretch">
                  <i className="fas fa-fire"></i>
                  <h3>
                    <a href="/privacy-policy">Privacy Policy</a>
                  </h3>
                </li>
                <li className="d-flex align-items-stretch">
                  <i className="fas fa-fire"></i>
                  <h3>
                    <a href="/terms-and-conditions">Terms and Conditions</a>
                  </h3>
                </li>
                <li className="d-flex align-items-stretch">
                  <i className="fas fa-fire"></i>
                  <h3>
                    <a href="/sitemap">Sitemap</a>
                  </h3>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="sitebox">
              <h2>
                <strong>Contact Us</strong>
              </h2>
              <ul className="sitecontact">
                <li className="d-flex align-items-stretch">
                  <span>
                    <i className="fas fa-envelope"></i>
                  </span>
                  <h3>
                    <strong>Email</strong>
                    <br />
                    <a href="mailto:contact@pixxmo.com">contact@pixxmo.com</a>
                  </h3>
                </li>
                <li className="d-flex align-items-stretch">
                  <span>
                    <i className="fas fa-phone-alt"></i>
                  </span>
                  <h3>
                    <strong>Phone Number</strong>
                    <br />
                    <a href="tel:+91-9588151671">+91-9588151671</a>
                  </h3>
                </li>
                <li className="d-flex align-items-stretch">
                  <span>
                    <i className="fas fa-map-marker-alt"></i>
                  </span>
                  <h3>
                    <strong>Address</strong>
                    <br />
                    <a href="https://g.page/pixXmo">
                      <span>
                        977, Sector-1 Main Road,
                        <br />
                        Rohtak, Haryana - 124001
                      </span>
                    </a>
                  </h3>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sitemap;
