import React from "react";
import { Helmet } from "react-helmet";

import "./extra.css";

const Faq = () => {
  return (
    <div>
      <Helmet>
        <title>pixXmo | FAQ</title>
      </Helmet>
      <div className="faqbx">
        <div className="faq-header">Frequently Asked Questions</div>
        <div className="faq-content">
          <div className="faq-question">
            <input id="q1" type="checkbox" className="panel" />
            <div className="plus">+</div>
            <label for="q1" className="panel-title">
              What is{" "}
              <strong>
                <a href="/">pixXmo</a>
              </strong>
              ?
            </label>
            <div className="panel-content">
              Pixxmo is India based IT solutions company that helps startups,
              SMEs, and businesses to grow their online presence, increase reach
              and engagement, and build brand reputation. We are specialized in
              providing all kinds of marketing and development services such as
              social media marketing, content marketing, branding, email
              marketing, search engine optimization, professional
              websites/applications ranging from simple corporate
              websites/applications to the most complex and sophisticated
              eCommerce platforms.
            </div>
          </div>
          <div className="faq-question">
            <input id="q2" type="checkbox" className="panel" />
            <div className="plus">+</div>
            <label for="q2" className="panel-title">
              Which services do{" "}
              <strong>
                <a href="/">pixXmo</a>
              </strong>{" "}
              provide?
            </label>
            <div className="panel-content">
              Pixxmo offers all kinds of digital marketing services which
              include Content Marketing, Email Marketing, Search Engine
              Optimization, Local SEO, Social Media Marketing, Google Analytics
              or Web Analytics, Performance Marketing, Branding, and Affiliate
              Marketing. Apart from this we also offer website designing and
              development, application development, software development.
            </div>
          </div>
          <div className="faq-question">
            <input id="q3" type="checkbox" className="panel" />
            <div className="plus">+</div>
            <label for="q3" className="panel-title">
              Do we provide free Digital Marketing consultation?
            </label>
            <div className="panel-content">
              Yes, the pixxmo team provides free Digital Marketing consultation.
            </div>
          </div>
          <div className="faq-question">
            <input id="q4" type="checkbox" className="panel" />
            <div className="plus">+</div>
            <label for="q4" className="panel-title">
              What is Digital Marketing?
            </label>
            <div className="panel-content">
              Digital Marketing is the process of getting your message out to
              people through websites, search engines. Social media, emails, and
              other digital platforms.
            </div>
          </div>
          <div className="faq-question">
            <input id="q5" type="checkbox" className="panel" />
            <div className="plus">+</div>
            <label for="q5" className="panel-title">
              How Digital Marketing can help my business?
            </label>
            <div className="panel-content">
              Digital Marketing can help your business in many different ways.
              Google’s Ads, Facebook Ads and Sponsored posts, LinkedIn Ads all
              offer you the opportunity to target your customers. Digital
              Marketing has numerous benefits for anyone’s business such as
              increased brand awareness, engagement, reach, conversion rates,
              and ultimately more revenue.{" "}
            </div>
          </div>
          <div className="faq-question">
            <input id="q6" type="checkbox" className="panel" />
            <div className="plus">+</div>
            <label for="q6" className="panel-title">
              What is Digital Marketing Agency?
            </label>
            <div className="panel-content">
              A Digital Marketing Agency is a type of marketing agency that
              promotes business through Digital Marketing.
            </div>
          </div>
          <div className="faq-question">
            <input id="q7" type="checkbox" className="panel" />
            <div className="plus">+</div>
            <label for="q7" className="panel-title">
              What is Marketing?
            </label>
            <div className="panel-content">
              Marketing is a process of finding, attracting, and satisfying
              customers.
            </div>
          </div>
          <div className="faq-question">
            <input id="q8" type="checkbox" className="panel" />
            <div className="plus">+</div>
            <label for="q8" className="panel-title">
              How to select a Digital Marketing Agency?
            </label>
            <div className="panel-content">
              You can choose a Digital Marketing Agency which is offering the
              best value over the price and also check their portfolio and
              experience.
            </div>
          </div>
          <div className="faq-question">
            <input id="q9" type="checkbox" className="panel" />
            <div className="plus">+</div>
            <label for="q9" className="panel-title">
              What is the estimate time frame for logo design?
            </label>
            <div className="panel-content">
              Once we have the relevant information needed to design a logo, it
              generally takes 4 to 5 working days to come up with logo design
              options.
            </div>
          </div>
          <div className="faq-question">
            <input id="q10" type="checkbox" className="panel" />
            <div className="plus">+</div>
            <label for="q10" className="panel-title">
              How long does it take before I can see the results?
            </label>
            <div className="panel-content">
              It generally takes around 3 to 6 months for the results you wish
              to seek and depends on how competitive your particular domain is.
            </div>
          </div>
          <div className="faq-question">
            <input id="q11" type="checkbox" className="panel" />
            <div className="plus">+</div>
            <label for="q11" className="panel-title">
              What industries have you worked with?
            </label>
            <div className="panel-content">
              We have worked with E-Commerce, Job Portal, Food, Travel,
              Healthcare, Real Estate, Technology, Education, and many more. We
              are pretty confident in serving your niche as we have a ton of
              experience.
            </div>
          </div>
          <div className="faq-question">
            <input id="q12" type="checkbox" className="panel" />
            <div className="plus">+</div>
            <label for="q12" className="panel-title">
              Are you flexible with your clients demand?
            </label>
            <div className="panel-content">
              Yes, We keep you first and keep the conversation open. At pixXmo,
              we believe in transparency and understand what specifics you
              require. We understand and explain the possibilities to you and
              work with you to build your domain authority over your
              competitors.
            </div>
          </div>
          <div className="faq-question">
            <input id="q13" type="checkbox" className="panel" />
            <div className="plus">+</div>
            <label for="q13" className="panel-title">
              What makes your services different?
            </label>
            <div className="panel-content">
              At pixxmo, we understand how important it is for SMEs, businesses,
              startups like yours to be able to market themselves effectively
              online in order to succeed in today’s competitive marketplace. We
              have the knowledge and experience needed to help your business
              grow through all forms of digital media including SEO, social
              media marketing, PPC, affiliate marketing, content marketing,
              email marketing campaigns, and many more.
              <ul>
                <li>On-Time Delivery</li>
                <li>Affordable</li>
                <li>We work as our business</li>
                <li>Transparent Reporting</li>
              </ul>
            </div>
          </div>
          <div className="faq-question">
            <input id="q14" type="checkbox" className="panel" />
            <div className="plus">+</div>
            <label for="q14" className="panel-title">
              Do you outsource any of your services?
            </label>
            <div className="panel-content">
              No, we don’t. We have everything in-house including content
              creation, marketing strategy optimization, link building, website
              design and development, and mobile application development.
            </div>
          </div>
          <div className="faq-question">
            <input id="q15" type="checkbox" className="panel" />
            <div className="plus">+</div>
            <label for="q15" className="panel-title">
              Do I need to sign a contract with <strong>pixxmo</strong> for your
              services?
            </label>
            <div className="panel-content">
              No, you don’t need to sign any contract or agreement as we operate
              on the On-Demand Model. You pay for what you need, and you can
              stop or pause your services as your requirements.
            </div>
          </div>
          <div className="faq-question">
            <input id="q16" type="checkbox" className="panel" />
            <div className="plus">+</div>
            <label for="q16" className="panel-title">
              What does your SEO reports cover? How frequently do you provide
              it?
            </label>
            <div className="panel-content">
              We clearly define report metrics and show the changes that have
              happened over some time. Usually, we provide monthly reporting
              with important factors like ranking, traffic, links built, and
              more. We try to make the reports easy to understand so that you
              can understand the specific changes that have occurred.
            </div>
          </div>
          <div className="faq-question">
            <input id="q17" type="checkbox" className="panel" />
            <div className="plus">+</div>
            <label for="q17" className="panel-title">
              Do you redesign existing websites or applications?
            </label>
            <div className="panel-content">
              Yes, we redesign the websites and applications as per the
              requirements. We re-design to the latest industry and web
              standards along with device compatibility. Also, we enhance major
              functionality as per the feasibility and requirements.
            </div>
          </div>
          <div className="faq-question">
            <input id="q18" type="checkbox" className="panel" />
            <div className="plus">+</div>
            <label for="q18" className="panel-title">
              What if I do not like the design?
            </label>
            <div className="panel-content">
              If you are not satisfied with the design options shared by us, we
              will provide new options to match your expectations based on your
              feedback. We provide up to 3 iterations for the design creation
              and review.
            </div>
          </div>
          <div className="faq-question">
            <input id="q19" type="checkbox" className="panel" />
            <div className="plus">+</div>
            <label for="q19" className="panel-title">
              Who owns the copyright of my logo once it is designed?
            </label>
            <div className="panel-content">
              The client holds the copyright of the approved logo designed by
              us.
            </div>
          </div>
          <div className="faq-question">
            <input id="q20" type="checkbox" className="panel" />
            <div className="plus">+</div>
            <label for="q20" className="panel-title">
              How much does it cost to build an application or a website?
            </label>
            <div className="panel-content">
              The costs depend on what type of application or website you want
              to build, what features you need, or for which platform you want
              to build.
            </div>
          </div>
          <div className="faq-question">
            <input id="q21" type="checkbox" className="panel" />
            <div className="plus">+</div>
            <label for="q21" className="panel-title">
              What is the average duration of website development and
              application development?
            </label>
            <div className="panel-content">
              The average duration is 7 to 10 days for a WordPress website and
              15-20 days for an eCommerce website and for application
              development is 2 months including publishing and testing, but
              usually, it depends on what type of application or website you
              want to build, what features you need, or for which platform you
              want to build for.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
