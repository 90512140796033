import React from "react";
import { Helmet } from "react-helmet";

import Contact from "../../components/contact/Contact";

import Work from "../../images/assets/Portfolio.png";
import Work1 from "../../images/portfolio/1.png";
import Work2 from "../../images/portfolio/2.png";
import Work3 from "../../images/portfolio/3.png";
import Work4 from "../../images/portfolio/4.png";
import Work5 from "../../images/portfolio/5.png";
import Work6 from "../../images/portfolio/6.png";
import Work7 from "../../images/portfolio/7.png";
import Work8 from "../../images/portfolio/8.png";

import "./portfolio.css";

const Portfolio = () => {
  return (
    <div>
      <Helmet>
        <title>pixXmo | Our Work</title>
      </Helmet>
      <div className="mainwork">
        <div className="mainhead">
          <h4>Let our work, speak out for itself…</h4>
          <h1>
            <strong>Our Work</strong>
          </h1>
          <h4>
            We believe that artistic limits reach infinity and beyond. With a
            team of developers, animators and designers who invest their full
            potential into every nuance of the work we execute, we present to
            you a glimpse…
          </h4>
        </div>
        <div className="mainbody">
          <div className="row">
            <div className="col-lg-6">
              <div className="pcards">
                <div className="pcardsimg">
                  <a
                    href="https://www.theganador.com/"
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    <img src={Work1} alt="The Ganador" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="pcards">
                <div className="pcardsimg">
                  <a
                    href="https://smartcityds.in/"
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    <img src={Work8} alt="Smart City Departmental Store" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="pcards">
                <div className="pcardsimg">
                  <a
                    href="https://www.evplugincharge.com/"
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    <img src={Work2} alt="EV Plugin Charge" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="pcards">
                <div className="pcardsimg">
                  <a
                    href="https://hansconcepts.netlify.app/"
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    <img src={Work3} alt="Hans Concepts" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="pcards">
                <div className="pcardsimg">
                  <a
                    href="https://superca.in/"
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    <img src={Work4} alt="Super CA" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="pcards">
                <div className="pcardsimg">
                  <a
                    href="https://thepureearth.com/"
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    <img src={Work5} alt="The Pure Earth" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="pcards">
                <div className="pcardsimg">
                  <a
                    href="https://aerometic.com/"
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    <img src={Work6} alt="Aerometic" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="pcards">
                <div className="pcardsimg">
                  <a
                    href="https://weihzstay.netlify.app/"
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    <img src={Work7} alt="Weihz Stay" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="servicecontact">
          <h1>
            Ready to Grow?
            <span>
              <strong> Let's Talk!</strong>
            </span>
          </h1>
          <h3>
            Learn how <strong>pixXmo</strong> growth stack could transform your
            business.
          </h3>
          <div className="row">
            <div className="col-lg-6">
              <div className="workimg">
                <img src={Work} alt="Our Services" />
              </div>
            </div>
            <div className="col-lg-6">
              <Contact />
            </div>
          </div>
        </div>
        {/* Ends */}
      </div>
    </div>
  );
};

export default Portfolio;
