import React from "react";
import { Helmet } from "react-helmet";
import Contact from "../../components/contact/Contact";

import "./contact.css";

const ContactUs = () => {
  return (
    <div>
      <Helmet>
        <title>pixXmo | Contact Us</title>
      </Helmet>
      <div className="contactus">
        <div className="mcontact">
          <h1>
            <strong>Contact Us</strong>
          </h1>
        </div>
        {/* Contact Us Form */}
        <div className="contactfrm">
          <h4>Let's Get Connected</h4>
          <h1>
            <strong>Have Question? Write a Message</strong>
          </h1>
          <h4>We will catch you as early as we recieve the message.</h4>
          <div className="row">
            <div className="col-lg-6">
              <div className="contacttxt">
                <div className="d-flex align-items-stretch contdetails">
                  <span>
                    <i className="fas fa-envelope"></i>
                  </span>
                  <h3>
                    MAIL TO OUR HR DEPARTMENT
                    <br />
                    <a href="mailto:contact@pixxmo.com">
                      <strong>contact@pixxmo.com</strong>
                    </a>
                  </h3>
                </div>
                <div className="d-flex align-items-stretch contdetails">
                  <span>
                    <i className="fas fa-phone-alt"></i>
                  </span>
                  <h3>
                    OUR CONTACT NUMBER
                    <br />
                    <a href="tel:9588151671">
                      <strong>+91-9588151671</strong>
                    </a>
                  </h3>
                </div>
                <div className="d-flex align-items-stretch contdetails">
                  <span>
                    <i className="fas fa-map-marker-alt"></i>
                  </span>
                  <h3>
                    OUR ADDRESS
                    <br />
                    <a href="https://g.page/pixXmo">
                      <strong>
                        977, Sector-1 Main Road, Rohtak, Haryana -124001
                      </strong>
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <Contact />
              {/* <div className="contactform">
                                <form>
                                    <div className="form-row">
                                        <div className="col-lg-6 mb-8">
                                            <label for="fullname">Full Name *</label>
                                            <input type="text" className="form-control" id="fullname" name="fullname" placeholder="First Name" required />
                                        </div>
                                        <div className="col-lg-6 mb-3">
                                            <label for="email">Email Address *</label>
                                            <input type="email" className="form-control" id="email" name="email" placeholder="example@abc.com" required />
                                        </div>
                                        <div className="col-lg-6 mb-3">
                                            <label for="company">Organization/ Company Name *</label>
                                            <input type="text" className="form-control" id="company" name="companyname" placeholder="pixxmo online services private limited" required />
                                        </div>
                                        <div className="col-lg-6 mb-3">
                                            <label for="mobile">Mobile Number *</label>
                                            <input type="tel" className="form-control" id="mobile" name="mobile" placeholder="+91-9876543210" required />
                                        </div>
                                        <div className="col-lg-12 mb-3">
                                            <label for="msg">Message *</label>
                                            <textarea className="form-control" name="msg" id="msg" cols="30" rows="5" required />
                                        </div>
                                    </div>
                                    <button className="btn subbtn" type="submit">
                                        Submit <i className="fas fa-chevron-right"></i>
                                    </button>
                                    <p className="text-center">
                                        <i className="fas fa-lock"></i> We hate spam, and we respect your privacy.
                                    </p>
                                </form>
                            </div> */}
            </div>
          </div>
        </div>
        {/* Ends */}
        {/* Google Map */}
        {/* <div className="map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3493.470580936859!2d76.62718791561952!3d28.88436118231904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d8583791d7655%3A0x2e4711615033c19b!2spixXmo%20Online%20Services%20Private%20Limited!5e0!3m2!1sen!2sin!4v1640882215707!5m2!1sen!2sin" allowfullscreen="" loading="lazy"></iframe>
                </div> */}
        {/* ends */}
      </div>
    </div>
  );
};

export default ContactUs;
