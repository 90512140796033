import React from 'react'
import { Helmet } from 'react-helmet'
import './error.css'

const Error = () => {
    return (
        <div>
            <Helmet>
                <title>404 - Not Found</title>
            </Helmet>
            <div className="perror">
                <div className="error">
                    <div className="glitch-wrapper">
                        <div className="glitch-text">
                            ERROR 404
                            <h1>
                                Oops, the page you're looking for doesn't exist.
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Error
