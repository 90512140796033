import React from "react";
import { Helmet } from "react-helmet";

import "./service.css";

import Contact from "../../components/contact/Contact";

import Service1 from "../../images/assets/icons/SEO_1.png";

const SEO = () => {
  return (
    <div>
      <Helmet>
        <title>pixXmo | Search Engine Optimization</title>
      </Helmet>
      <div className="seo">
        <h1>
          <strong>Search Engine Optimization</strong>
        </h1>
        <h3>Looking for SEO services?</h3>
        <h3>
          Start getting more qualified traffic, increased reach and engagement,
          and higher rankings on SERPs by search engine optimization services
          for your business website.
        </h3>
        <h2>Types of SEO</h2>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h3>
            <strong>Local SEO</strong>
          </h3>
        </span>
        <p>
          Practice that helps the local service providers to get local
          /customers.
        </p>
        <p>
          Useful for local businesses, local restaurants, etc. that need local
          exposure.
        </p>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h3>
            <strong>ECommerce SEO</strong>
          </h3>
        </span>
        <p>
          Practiced on businesses that run an e-commerce website and helps in
          growing their ROI through the purchase of their products and services
          by the internal user.
        </p>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h3>
            <strong>Enterprise SEO</strong>
          </h3>
        </span>
        <p>
          The strategy of improving organic search presence for enterprise-level
          sites consisting of hundreds or even millions of pages. The scope of
          work to be done and the online presence to be managed increases
          compared to smaller sites targeting the same areas of focus.
        </p>
        <hr />
        <h2>
          <strong>How we work?</strong>
        </h2>
        <div className="row">
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Keyword Analysis</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Backlink Building</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Content Creation</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Competitor Analysis</h4>
            </div>
          </div>
        </div>
        <hr />
        <h2>
          <strong>How do SEO services help?</strong>
        </h2>
        <h5>
          Search Engine Optimization (SEO) is the inbound marketing process of
          increasing the quantity and quality of traffic through organic search
          results.
        </h5>
        <div className="row">
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Quality Traffic</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Free Traffic</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Conversion</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Better Online Visibility</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Higher Domain Authority</h4>
            </div>
          </div>
        </div>
        <hr />
        <h2>
          <strong>Why Should you invest in SEO Services?</strong>
        </h2>
        <h5>
          SEO can help you to optimize your online visibility, increase reach,
          increase website traffic, and better chance to provide your product or
          service to a greater number of people. <br />
          SEO in Helping your Business Growth:
        </h5>
        <div className="row">
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Build Trust with your customers</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Visibility Attracts Clientele</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Promotes Positive User-Experience</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Helps Build Up Engagement and Conversion Rate</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Increase Lead Generation</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Make your Brand Valuable</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Stay Ahead of the Competition</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Promote Brand Awareness</h4>
            </div>
          </div>
        </div>
        <hr />
      </div>
      {/* Request a Quote Section */}
      <div className="servicecontact">
        <h1>
          How we can help{" "}
          <span>
            <strong>your business grow.</strong>
          </span>
        </h1>
        <div className="row">
          <div className="col-lg-6">
            <div className="serviceimg">
              <img src={Service1} alt="Our Services" />
            </div>
          </div>
          <div className="col-lg-6 servicecnt">
            <Contact />
          </div>
        </div>
      </div>
      {/* Ends */}
    </div>
  );
};

export default SEO;
