import React from "react";
import { Helmet } from "react-helmet";
import "./about.css";

import Contact from "../../components/contact/Contact";

import Work from "../../images/assets/Portfolio.png";

import AboutImg from "../../images/assets/icons/Social.png";

const About = () => {
  return (
    <div>
      <Helmet>
        <title>pixXmo | About Us</title>
      </Helmet>
      <div className="about">
        <div className="row">
          <div className="col-lg-4">
            <div className="about-img">
              <img src={AboutImg} alt="pixxmo" />
            </div>
          </div>
          <div className="col-lg-8">
            <div className="about-txt">
              <h1>
                <strong>pixXmo </strong>
                IT Solutions Company
              </h1>
              <h3>
                <strong>Design is Thinking and We call it pixXmo</strong>
              </h3>
              <h4>
                <i>"Your pixel will have its moment!"</i>
              </h4>
              <h4>Welcome to pixXmo.</h4>
              <h4>
                pixXmo is not just a brand…it’s a manifestation of our vision
              </h4>
              <h4>
                With a vision to introduce new, interesting, and pocket-friendly
                opportunities in the market, pixXmo opened its branches to
                alternative possibilities. Our goal is to provide competent,
                novel, and affordable services to all. From striking visuals to
                artistic insights, pixXmo offers designs and handles the work of
                social media posts on a broad spectrum, from posters and
                advertisements to promotional videos and website designs.
              </h4>
              <h4>
                The team at pixXmo incorporates individuals with far-reaching
                capacities to contribute effectively to the projects with
                graphic designing such as UI&UX, posters, logos, labels, 3D
                models, and more to offer. We encourage our members to showcase
                their creative expressions while keeping the clients’ insights
                in mind to bring out a more efficacious work.
              </h4>
            </div>
          </div>
        </div>
        <div className="abouttxt">
          <h4>
            It is our belief in a seamless connection between strategy and
            creativity, which makes pixXmo offer you a service of ultimate
            satisfaction and brilliance. It has been a collective effort of one
            and all which has brought us here together and keeps us dignified as
            a brand. We deal with clients and projects on such a varied scale
            that it makes this experience all the more interesting and keeps us
            going and doing better. We live in an ever transitioning world,
            where every day is a different one. To be on the same wavelength as
            the change is a must to survive. And so we as a collective force
            ensure growth in every way possible.
          </h4>
          <h1>What do we do?</h1>
          <h4>
            pixXmo is an IT solution that helped innumerable aspiring startups,
            SMEs, and businesses in getting high-quality business/ corporate
            websites that not only provide an innovative appearance but help to
            increase reach and engagement with your target audience and help to
            get the customers.
          </h4>
          <div className="row">
            <div className="col-lg-12">
              <div className="box" data-aos="zoom-in-right">
                <div className="boximg"></div>
                <div className="boxtxt">
                  <h3>
                    <strong>
                      Web Development/ Mobile Application development
                    </strong>
                  </h3>
                  <h4>
                    Having designed, developed, and managed a multitude of
                    business websites or applications on all the popular
                    platforms for a variety of industries, We have vast
                    experience in delivering quality outcomes for all your
                    needs. Your business website or application is a better
                    illustration of your business and it should be a great blend
                    of every ingredient which is essential to ensure its
                    performance. Consult the experts at ‘pixXmo’ to get the
                    best-quality business website or application that not only
                    perform well on distinct browsers but also support your
                    digital marketing efforts.
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="box" data-aos="zoom-in-right">
                <div className="boximg"></div>
                <div className="boxtxt">
                  <h3>
                    <strong>Digital Marketing Services</strong>
                  </h3>
                  <h4>
                    We as an IT Solution Company ensure to offer you the most
                    effective and suitable digital marketing assistance to
                    assist the business. Contact us to help your business with
                    services such as:
                  </h4>
                  <ul>
                    <li className="d-flex align-items-stretch ">
                      <i className="fas fa-fire"></i>
                      <h4>Search Engine Optimization</h4>
                    </li>
                    <li className="d-flex align-items-stretch ">
                      <i className="fas fa-fire"></i>
                      <h4>Social Media Marketing</h4>
                    </li>
                    <li className="d-flex align-items-stretch ">
                      <i className="fas fa-fire"></i>
                      <h4>Social Media Optimization</h4>
                    </li>
                    <li className="d-flex align-items-stretch ">
                      <i className="fas fa-fire"></i>
                      <h4>Pay Per Click (PPC)</h4>
                    </li>
                    <li className="d-flex align-items-stretch ">
                      <i className="fas fa-fire"></i>
                      <h4>Email Marketing</h4>
                    </li>
                    <li className="d-flex align-items-stretch ">
                      <i className="fas fa-fire"></i>
                      <h4>Content Marketing</h4>
                    </li>
                    <li className="d-flex align-items-stretch ">
                      <i className="fas fa-fire"></i>
                      <h4>Link CleanUp</h4>
                    </li>
                    <li className="d-flex align-items-stretch ">
                      <i className="fas fa-fire"></i>
                      <h4>Online Reputation Management</h4>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="box" data-aos="zoom-in-right">
                <div className="boximg"></div>
                <div className="boxtxt">
                  <h3>
                    <strong>Custom Software</strong>
                  </h3>
                  <h4>
                    Our team experts ensure a secure and fully featured custom
                    software like ERP and CRM, CMS, business process automation
                    system, and many more for SMEs, Startups, and businesses.
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <h4>
            <strong>
              We have a dedicated team of Google Certified professionals who
              manage and keep our web delivery standards conforming to SEO
              required guidelines.
            </strong>
          </h4>
          <h4>
            Interested in learning more about our services or need any
            assistance, contact pixXmo today.
          </h4>
        </div>
        <div className="servicecontact">
          <h1>
            Ready to Grow?
            <strong> Let's Talk!</strong>
          </h1>
          <h3>
            Learn how <strong>pixXmo</strong> growth stack could transform your
            business.
          </h3>
          <div className="row">
            <div className="col-lg-6">
              <div className="workimg">
                <img src={Work} alt="Our Services" />
              </div>
            </div>
            <div className="col-lg-6">
              <Contact />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
