import React from "react";
import { Helmet } from "react-helmet";

import "./service.css";

import Contact from "../../components/contact/Contact";

import Service1 from "../../images/assets/icons/GD.png";

const Graphics = () => {
  return (
    <div>
      <Helmet>
        <title>pixXmo | Graphics Designing</title>
      </Helmet>
      <div className="seo">
        <h1>
          <strong>Graphics Designing</strong>
        </h1>
        <h3>
          What is quicker, easier, and more accessible is probably an
          application. How many times do we hear someone saying that there is an
          app for this? Applications that are usually referred to as apps are
          taking the entire industry and how we see and access things by storm.
          We design, deploy and develop these apps at minimalist prices with a
          focus on conveying what is yours.
        </h3>
        <h2>
          <strong>Services we offer</strong>
        </h2>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>Logo Design</h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>Business Card Design</h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>Email Marketing Design</h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>Newsletter Design</h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>Banner Ad Design</h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>Flyer Design</h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>Advertisement Design</h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>Catalog Design</h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>Brochure Design</h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>Magazine Design</h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>Vector Design</h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>Product and Merchandise Design</h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>Illustration Design</h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>3D Design</h4>
        </span>
        <hr />
        <h2>
          <strong>Why did you choose us?</strong>
        </h2>
        <h5>
          Graphic design involves designing and developing a logo that can help
          you to build a brand image, boost your marketing and advertising
          campaigns. It can help inform, or educate your target audience and
          convert them into customers.
        </h5>
        <div className="row">
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Increase brand recognition</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Building trusts and goodwill</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Higher conversion rate</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Positive first impression</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Indicated professionalism</h4>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="seobox">
              <h4>Builds credibility</h4>
            </div>
          </div>
        </div>
        <hr />
        <h2>
          <strong>How we work</strong>
        </h2>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>Work with you to understand your business needs</h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>
            Discuss the best designs that reflect your business or product
          </h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>
            Sample designs will be created and furnished for your approvals
          </h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>
            After approval, it will be rendered and enhanced into the final
            format
          </h4>
        </span>
        <span className="d-flex align-items-stretch">
          <i className="fas fa-fire"></i>
          <h4>It will be sent in your preferred format.</h4>
        </span>
        <hr />
        <h2>
          <strong>Tools we use</strong>
        </h2>
        <h5>
          Our products are built on top of modern frameworks and technologies
        </h5>
        <div className="row">
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Adobe Illustrator</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Adobe Photoshop</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Sketch3</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Marvel</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>WebFlow</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Canva</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Adobe After Effect</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Adobe XD</h4>
            </div>
          </div>
        </div>
        <hr />
      </div>
      {/* Request a Quote Section */}
      <div className="servicecontact">
        <h1>
          How we can help{" "}
          <span>
            <strong>your business grow.</strong>
          </span>
        </h1>
        <div className="row">
          <div className="col-lg-6">
            <div className="serviceimg">
              <img src={Service1} alt="Our Services" />
            </div>
          </div>
          <div className="col-lg-6 servicecnt">
            <Contact />
          </div>
        </div>
      </div>
      {/* Ends */}
    </div>
  );
};

export default Graphics;
