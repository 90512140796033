import axios from "axios";
import React from "react";
import "./contact.css";

export default class Contact extends React.Component {
  state = {
    name: "",
    email: "",
    cname: "",
    phone: "",
    message: "",
    sent: false,
  };

  // handle inputs

  handleName = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  handleEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  handleCname = (e) => {
    this.setState({
      cname: e.target.value,
    });
  };

  handlePhone = (e) => {
    this.setState({
      phone: e.target.value,
    });
  };

  handleMessage = (e) => {
    this.setState({
      message: e.target.value,
    });
  };

  // end handle inputs

  // submit form data
  formSubmit = (e) => {
    e.preventDefault();
    let data = {
      name: this.state.name,
      email: this.state.email,
      cname: this.state.cname,
      phone: this.state.phone,
      message: this.state.message,
    };
    axios
      .post("https://sendmail.pixxmo.com/send-email", data)
      .then((res) => {
        this.setState(
          {
            sent: true,
          },
          this.resetForm()
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // ends

  // reset form data
  resetForm = () => {
    this.setState({
      name: "",
      email: "",
      cname: "",
      phone: "",
      message: "",
    });
    setTimeout(() => {
      this.setState({
        sent: false,
      });
    }, 3000);
  };

  // end reset form data

  render() {
    return (
      <div>
        <div className="contactform">
          <form id="contactForm" onSubmit={this.formSubmit}>
            <div className="form-row">
              <div className="col-lg-6 mb-8">
                <label htmlFor="name">Full Name *</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="First Name"
                  required
                  value={this.state.name}
                  onChange={this.handleName}
                />
              </div>
              <div className="col-lg-6 mb-3">
                <label htmlFor="email">Email Address *</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="example@abc.com"
                  required
                  value={this.state.email}
                  onChange={this.handleEmail}
                />
              </div>
              <div className="col-lg-6 mb-3">
                <label htmlFor="cname">Organization/ Company Name *</label>
                <input
                  type="text"
                  className="form-control"
                  id="cname"
                  name="cname"
                  placeholder="pixxmo online services private limited"
                  required
                  value={this.state.cname}
                  onChange={this.handleCname}
                />
              </div>
              <div className="col-lg-6 mb-3">
                <label htmlFor="phone">Mobile Number *</label>
                <input
                  type="tel"
                  className="form-control"
                  id="phone"
                  name="phone"
                  placeholder="+91-9876543210"
                  required
                  value={this.state.phone}
                  onChange={this.handlePhone}
                />
              </div>
              <div className="col-lg-12 mb-3">
                <label htmlFor="message">Message *</label>
                <textarea
                  className="form-control"
                  name="message"
                  id="message"
                  cols="30"
                  rows="5"
                  required
                  value={this.state.message}
                  onChange={this.handleMessage}
                />
              </div>
            </div>
            <div className={this.state.sent ? "msgAppear" : "msg"}>
              <p>
                <b>Thank you for getting in touch!</b> <br />
                We appreciate you contacting us. One of our colleagues will get
                back in touch with you soon! Have a great day!
              </p>
            </div>
            <button className="btn subbtn" type="submit">
              Submit <i className="fas fa-chevron-right"></i>
            </button>
            <p className="text-center">
              <i className="fas fa-lock"></i> We hate spam, and we respect your
              privacy.
            </p>
          </form>
        </div>
      </div>
    );
  }
}
