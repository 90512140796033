import React from "react";
import { Helmet } from "react-helmet";

import "./service.css";

import Contact from "../../components/contact/Contact";

import SMM2 from "../../images/assets/icons/SMM_1.png";

const SMM = () => {
  return (
    <div>
      <Helmet>
        <title>pixXmo | Social Media Marketing</title>
      </Helmet>
      <div className="seo">
        <h1>
          <strong>Social Media Marketing</strong>
        </h1>
        <h3>
          The most important benefit of Social Media Marketing Services is it
          helps you to interact with your online community and you can promote
          your product or services and get thousands of leads from reliable
          social media marketing services.
        </h3>
        <h3>
          If you’re even a bit stumped about whether or not to take social media
          marketing services in India for your business, then below are some of
          the benefits of using social media marketing services for your
          business in India:
        </h3>
        <div className="row">
          <div className="col-lg-3">
            <div className="smmbox">
              <span className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h3>
                  <strong>Increased Brand Awareness</strong>
                </h3>
              </span>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="smmbox">
              <span className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h3>
                  <strong>More Inbound Traffic</strong>
                </h3>
              </span>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="smmbox">
              <span className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h3>
                  <strong>Higher Conversion Rates</strong>
                </h3>
              </span>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="smmbox">
              <span className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h3>
                  <strong>Low Marketing Budget</strong>
                </h3>
              </span>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="smmbox">
              <span className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h3>
                  <strong>Improved Brand Loyalty</strong>
                </h3>
              </span>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="smmbox">
              <span className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h3>
                  <strong>Enhance Brand Authority</strong>
                </h3>
              </span>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="smmbox">
              <span className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h3>
                  <strong>Gain Marketplace Insights</strong>
                </h3>
              </span>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="smmbox">
              <span className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h3>
                  <strong>Powerful Reporting</strong>
                </h3>
              </span>
            </div>
          </div>
        </div>
        <h2>
          <strong>Our Social Media Marketing Services Offer</strong>
        </h2>
        <div className="row">
          <div className="col-lg-6">
            <div className="seobox">
              <h4>Multi-Platform Social Media Marketing Experience</h4>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="seobox">
              <h4>Content Development Capabilities</h4>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="seobox">
              <h4>Data-Driven Decision Making</h4>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="seobox">
              <h4>Detailed Reporting</h4>
            </div>
          </div>
        </div>
        <hr />
        <h2>
          <strong>Our Social Media Marketing Services Process</strong>
        </h2>
        <div className="row">
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Find and Finalize Goals</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Define your Audience</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Research on Competitors</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Decide Channels</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Plan Budget</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Build Relationship</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Manage Reputation</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Test and Improve the Strategy</h4>
            </div>
          </div>
        </div>
        <hr />
        <h2>
          <strong>Social Media Marketing Includes</strong>
        </h2>
        <div className="row">
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Facebook Marketing</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Instagram Marketing</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Youtube</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>LinkedIn</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Twitter</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Pinterest</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Snapchat</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>WhatsApp</h4>
            </div>
          </div>
        </div>
        <hr />
        <h2>
          <strong>
            Benefits of Hiring <a href="/">pixXmo</a> for Social Media Marketing
          </strong>
        </h2>
        <div className="row">
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Build Brand Awareness and Authenticity</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Generate Leads</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Connect Social Posts to Opportunities</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Grow your Audience</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Generate Unique Content at Scale</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Improved Search Engine Rankings</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Higher Conversion Rates</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="seobox">
              <h4>Stay Top of Mind with Key People</h4>
            </div>
          </div>
        </div>
        <hr />
      </div>
      {/* Request a Quote Section */}
      <div className="servicecontact">
        <h1>
          How we can help{" "}
          <span>
            <strong>your business grow.</strong>
          </span>
        </h1>
        <div className="row">
          <div className="col-lg-6">
            <div className="serviceimg">
              <img src={SMM2} alt="Our Services" />
            </div>
          </div>
          <div className="col-lg-6 servicecnt">
            <Contact />
          </div>
        </div>
      </div>
      {/* Ends */}
    </div>
  );
};

export default SMM;
