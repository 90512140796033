import React from "react";
import Logo from "../../images/logo/pixxmo.png";
import "./footer.css";

const Footer = () => {
  return (
    <div>
      <footer>
        <div className="container-fluid">
          <div className="row">
            {/* About */}
            <div className="col-lg-4">
              <a href="/">
                <img
                  className="logoimg"
                  src={Logo}
                  alt="pixxmo online services private limited"
                />
              </a>
              <h4>
                pixXmo is an IT solutions company providing innovative website
                design and development, mobile application development, SEO
                services, and many more to increase online visibility, reach,
                engagement, and build brand reputation.
              </h4>
              <h4>
                pixXmo works on different IT Services to help startups,
                businesses, SMEs, and more, to get dynamic and professional
                websites and applications ranging from simple corporate to the
                most complex and sophisticated platforms that run quickly, which
                are easy to use as well as cost and time effective.
              </h4>
              <div className="quotep1">
                <a href="/contactus" className="quotelink">
                  <p>Request a Quote</p>
                </a>
              </div>
            </div>
            {/* end */}
            {/* Our Services */}
            <div className="col-lg-2 services">
              <h2>Our Services</h2>
              <ul>
                <li>
                  <a href="/search-engine-optimization">
                    Search Engine Optimization
                  </a>
                </li>
                <li>
                  <a href="/social-media-marketing">Social Media Marketing</a>
                </li>
                <li>
                  <a href="/pay-per-click-ads">Pay Per Click Advertising</a>
                </li>
                <li>
                  <a href="/graphics-designing">Graphics Designing</a>
                </li>
                <li>
                  <a href="/website-design-and-development">
                    Website Design and Development
                  </a>
                </li>
                <li>
                  <a href="/mobile-application-development">
                    Mobile Application Development
                  </a>
                </li>
                <li>
                  <a href="/custom-software-development">
                    Custom Software Development
                  </a>
                </li>
              </ul>
            </div>
            {/* Ends */}
            {/* Our Expertise */}
            <div className="col-lg-2 services">
              <h2>Our Expertise</h2>
              <ul>
                <li>
                  {/* <a href="/hotel-booking-system">
                                    </a> */}
                  Hotel Booking System
                </li>
                <li>
                  {/* <a href="/online-food-order-system">
                                    </a> */}
                  Online Food Order System
                </li>
                <li>
                  {/* <a href="/billing-system">
                                    </a> */}
                  Billing System
                </li>
                <li>
                  {/* <a href="/doctor-booking-system">
                                    </a> */}
                  Doctor Booking System
                </li>
                <li>
                  {/* <a href="/mlm-software">
                                    </a> */}
                  MLM Software Development
                </li>
                <li>
                  {/* <a href="/online-portals">
                                    </a> */}
                  Web Portals
                </li>
                <li>
                  {/* <a href="/classifieds-web-development">
                                    </a> */}
                  Classifieds Website Development
                </li>
              </ul>
            </div>
            {/* Ends */}
            {/* Quick Links */}
            <div className="col-lg-2 services">
              <h2>Useful Links</h2>
              <ul>
                <li>
                  <a href="/aboutus">About Us</a>
                </li>
                <li>
                  <a href="/our-services">Our Services</a>
                </li>
                <li>
                  <a href="/portfolio">Portfolio</a>
                </li>
                <li>
                  <a href="faq">Frequently Asked Questions</a>
                </li>
                <li>
                  <a href="/privacy-policy">Privacy Policy</a>
                </li>
                <li>
                  <a href="/terms-and-conditions">Terms and Conditions</a>
                </li>
                <li>
                  <a href="/sitemap">Sitemap</a>
                </li>
              </ul>
            </div>
            {/* Ends */}
            {/* Contact Us */}
            <div className="col-lg-2 contact">
              <h2>Contact Us</h2>
              <ul>
                <li className="d-flex align-items-stretch">
                  <span>
                    <i className="fas fa-envelope"></i>
                  </span>
                  <h4>
                    <strong>Email</strong>
                    <br />
                    <a href="mailto:contact@pixxmo.com">contact@pixxmo.com</a>
                  </h4>
                </li>
                <li className="d-flex align-items-stretch">
                  <span>
                    <i className="fas fa-phone-alt"></i>
                  </span>
                  <h4>
                    <strong>Phone Number</strong>
                    <br />
                    <a href="tel:+91-9588151671">+91-9588151671</a>
                  </h4>
                </li>
                <li className="d-flex align-items-stretch">
                  <span>
                    <i className="fas fa-map-marker-alt"></i>
                  </span>
                  <h4>
                    <strong>Address</strong>
                    <br />
                    <a href="https://g.page/pixXmo">
                      <span>
                        977, Sector-1 Main Road,
                        <br />
                        Rohtak, Haryana - 124001
                      </span>
                    </a>
                  </h4>
                </li>
              </ul>
            </div>
            {/* Ends */}
            {/* Social Media Links */}
            <div className="col-md-12 text-center social">
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/pxxmo"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/pix.x.mo/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/pixxmo"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/pixxmo"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UC0H8of1mfwfeIrDal1a17Ew"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-youtube"></i>
                  </a>
                </li>
              </ul>
            </div>
            {/* Ends */}
            {/* CopyRight */}
            <div className="col-md-12 text-center copy">
              Copyright © 2020-2021{" "}
              <a href="/">
                <strong>Pixxmo Online Services Private Limited</strong>
              </a>
              . All rights reserved.
            </div>
            {/* Ends */}
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
