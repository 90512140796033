import React from "react";
import { Helmet } from "react-helmet";

import "./extra.css";

const Terms = () => {
  return (
    <div>
      <Helmet>
        <title>pixXmo | Terms and Conditions</title>
      </Helmet>
      <div className="extra">
        <h1>Terms and Conditions</h1>
        <h5>Last updated: February 11, 2022</h5>
        <h4>
          <strong>
            Please read these Terms and Conditions ("Terms", "Terms and
            Conditions") carefully before using the{" "}
            <a href="/">https://www.pixxmo.com</a> website (the "Service")
            operated by pixXmo ("us", "we", or "our").
          </strong>
        </h4>
        <br />
        <h3>
          <strong>AGREEMENT TO TERMS</strong>
          <hr />
        </h3>
        <ul>
          <li>
            <h4>
              These Terms and Conditions (hereinafter also referred to as “T&C”)
              constitute a legally binding agreement made between you, whether
              personally or on behalf of an entity (hereinafter also referred to
              as “you”), and Pixxmo Online Services Pvt Ltd, located at 977,
              Sector-1, Near Sector-1 Main Market, Rohtak, Haryana, India –
              124001, concerning your access to and use of the pixXmo (
              <a href="/">https://pixxmo.com/</a>) website as well as any
              related applications (hereinafter also referred to as 'the Site').
            </h4>
            <h4>
              The Site provides a host of facilities to you. You can hire us for
              building any mobile, web, and computer App. (Services). You agree
              that by accessing the Site and/or Services, you have read,
              understood, and agree to be bound by all of these Terms and
              Conditions.
            </h4>
            <h4>
              If you do not agree with any of these Terms and Conditions, then
              you are prohibited from using the Site and Services and you must
              discontinue use immediately. We recommend that you print a copy of
              these Terms and Conditions for future reference.
            </h4>
          </li>
          <li>
            <h4>
              The additional policies set out below, as well as any supplemental
              terms and conditions or documents that may be posted on the Site
              from time to time, are expressly incorporated by reference.
            </h4>
          </li>
          <li>
            <h4>
              We may make changes to these Terms and Conditions as and when
              required. The updated version will be published on the website and
              can be identified by the Last Update Date. You are responsible for
              reviewing these Terms and Conditions from time to time to stay
              informed of updates. Your use of the Site represents that you have
              accepted such changes.
            </h4>
          </li>
          <li>
            <h4>
              We may update or change the Site from time to time to reflect
              changes based on the business and legal requirements. There may be
              information on the Site that contains errors, inaccuracies, or
              omissions that may relate to the Services, including descriptions,
              and other information. We reserve the right to correct them and to
              change or update the information at any time, without prior
              notice.
            </h4>
          </li>
          <li>
            <h4>
              Our site is directed to people residing in India. The information
              provided on the Site is not intended for distribution to or use by
              any person or entity in any jurisdiction or country where such
              distribution or use would be contrary to law or regulation or
              which would subject us to any registration requirement within such
              jurisdiction or country.
            </h4>
          </li>
          <li>
            <h4>
              The Site is intended for users who are at least 18 years old. If
              you are under the age of 18, you are not permitted to use the
              Services without parental permission.
            </h4>
          </li>
          <li>
            <h4>
              Additional policies which also apply to your use of the Site
              include:
            </h4>
            <h4>
              Our Privacy Notice{" "}
              <a href="/privacy-policy">https://pixxmo.com/privacy-policy</a>
            </h4>
          </li>
        </ul>
        <br />
        <h3>
          <strong>ACCEPTABLE USE</strong>
          <hr />
        </h3>
        <ul>
          <li>
            <h4>
              You may not access or use the Site for any purpose other than that
              for which we make the site and our services available. The Site
              may not be used in connection with any commercial endeavors except
              those that are specifically endorsed or approved by us.
            </h4>
          </li>
          <li>
            <h4>As a user of this Site, you agree not to:</h4>
            <ul>
              <li className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h4>
                  Systematically retrieve data or other content from the Site to
                  a compiled database or directory without written permission
                  from us.
                </h4>
              </li>
              <li className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h4>
                  Make any unauthorized use of the Site, including creating user
                  accounts under false pretenses
                </h4>
              </li>
              <li className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h4>
                  Circumvent, disable, or otherwise interfere with
                  security-related features of the Site, including features that
                  prevent or restrict the use or copying of any content or
                  enforce limitations on the use
                </h4>
              </li>
              <li className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h4>
                  Engage in unauthorized framing of or linking to the Site
                </h4>
              </li>
              <li className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h4>
                  The trick, defraud, or mislead us and other users of our site
                  or service
                </h4>
              </li>
              <li className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h4>
                  Make improper use of our support services, or submit false
                  reports of abuse or misconduct
                </h4>
              </li>
              <li className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h4>
                  Engage in any automated use of the system, such as using
                  scripts to send comments or messages, or using any data
                  mining, robots, or similar data gathering and extraction tools
                </h4>
              </li>
              <li className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h4>
                  Interfere with, disrupt, or create an undue burden on the Site
                  or the networks and services connected to the Site
                </h4>
              </li>
              <li className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h4>
                  Attempt to impersonate another user or person, or use the
                  username of another user
                </h4>
              </li>
              <li className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h4>
                  Use any information obtained from the Site in order to harass,
                  abuse, or harm another person
                </h4>
              </li>
              <li className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h4>
                  Use the Site or our content as part of any effort to compete
                  with us or to create a revenue-generating endeavor or
                  commercial enterprise
                </h4>
              </li>
              <li className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h4>
                  Decipher, decompile, disassemble, or reverse engineer any of
                  the software comprising or in any way making up a part of the
                  Site
                </h4>
              </li>
              <li className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h4>
                  Attempt to access any portion of the Site that you are
                  restricted from accessing
                </h4>
              </li>
              <li className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h4>
                  Harass, annoy, intimidate, or threaten any of our employees,
                  agents, or other users
                </h4>
              </li>
              <li className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h4>
                  Delete the copyright or other proprietary rights notice from
                  any of the content
                </h4>
              </li>
              <li className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h4>Copy or adapt the Site’s software</h4>
              </li>
              <li className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h4>
                  Upload or transmit (or attempt to upload or to transmit)
                  viruses, Trojan horses, or other material that interferes with
                  any party’s uninterrupted use and enjoyment of the Site, or
                  any material that acts as a passive or active information
                  collection or transmission mechanism
                </h4>
              </li>
              <li className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h4>
                  Use, launch, or engage in any automated use of the system,
                  such as using scripts to send comments or messages, robots,
                  scrapers, offline readers, or similar data gathering and
                  extraction tools
                </h4>
              </li>
              <li className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h4>
                  Disparage, tarnish, or otherwise harm, in our opinion, us
                  and/or the Site
                </h4>
              </li>
              <li className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h4>
                  Use the Site in a manner inconsistent with any applicable laws
                  or regulations
                </h4>
              </li>
              <li className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h4>
                  Misrepresent experience, skills, or information about you or
                  other User
                </h4>
              </li>
              <li className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h4>
                  Falsely imply a relationship with us or another company with
                  whom you do not have a relationship.
                </h4>
              </li>
            </ul>
          </li>
        </ul>
        <br />
        <h3>
          <strong>DISCLAIMER/ LIMITATION OF LIABILITY</strong>
          <hr />
        </h3>
        <ul>
          <li>
            <h4>
              We do not own the software/codes being shared on the website
              unless specifically mentioned in the description of the
              software/codes. These software/codes available on the website are
              being provided on an as-is and as-available basis without any
              modification/review by us.
            </h4>
          </li>
          <li>
            <h4>
              You agree that your use of the Site and/or Services will be at
              your sole risk except as expressly set out in these Terms and
              Conditions. All warranties, terms, conditions, and undertakings,
              express or implied (including by statute, custom or usage, a
              course of dealing, or common law) in connection with the Site and
              Services and your use thereof including, without limitation, the
              implied warranties of satisfactory quality, fitness for a
              particular purpose and non-infringement are excluded to the
              fullest extent permitted by applicable law.
            </h4>
          </li>
          <li>
            <h4>
              We make no warranties or representations about the accuracy or
              completeness of the Site’s content and are not liable for any (1)
              errors or omissions in content; (2) any unauthorized access to or
              use of our servers and/or any and all personal information and/or
              financial information stored on our server; (3) any interruption
              or cessation of transmission to or from the site or services;
              and/or (4) any bugs, viruses, trojan horses, or the like which may
              be transmitted to or through the site by any third party. We will
              not be responsible for any delay or failure to comply with our
              obligations under these Terms and Conditions if such delay or
              failure is caused by an event beyond our reasonable control.
            </h4>
          </li>
          <li>
            <h4>Our responsibility for loss or damage suffered by you:</h4>
            <ul>
              <li className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h4>
                  We do not exclude or limit in any way our liability to you
                  where it would be unlawful to do so. This includes liability
                  for death or personal injury caused by our negligence or the
                  negligence of our employees, agents, or subcontractors and for
                  fraud or fraudulent misrepresentation.
                </h4>
              </li>
              <li className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h4>
                  If we fail to comply with these Terms and Conditions, we will
                  be responsible for loss or damage you suffer that is a
                  foreseeable result of our breach of these Terms and
                  Conditions, but we would not be responsible for any loss or
                  damage that were not foreseeable at the time you started using
                  the Site/Services.
                </h4>
              </li>
              <li className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h4>
                  Please note that we only provide our Site knowledge sharing,
                  domestic and private use. You agree not to use our Site for
                  any commercial or business purposes, and we have no liability
                  to you for any loss of profit, loss of business, business
                  interruption, or loss of business opportunity.
                </h4>
              </li>
              <li className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h4>
                  If any software/code is shared via our website and is owned by
                  us then you have legal rights in relation to software/code
                  that are faulty or not as described. Nothing in these Terms
                  and Conditions will affect these legal rights.
                </h4>
              </li>
              <li className="d-flex align-items-stretch">
                <i className="fas fa-fire"></i>
                <h4>
                  Not with standing anything to the contrary contained in the
                  Disclaimer/Limitation of Liability section, our liability to
                  you for any cause whatsoever and regardless of the form of the
                  action, will at all times be limited to a total aggregate
                  amount equal to the greater of (a) the sum of INR 10,000/- or
                  (b) the amount paid, if any, by you to us for the
                  Services/Site during the six (6) month period prior to any
                  cause of action arising.
                </h4>
              </li>
            </ul>
          </li>
        </ul>
        <br />
        <h3>
          <strong>TERMS AND TERMINATION</strong>
          <hr />
        </h3>
        <ul>
          <li>
            <h4>
              These Terms and Conditions shall remain in full force and effect
              while you use the Site or Services or are otherwise a user of the
              Site, as applicable. You may terminate your use or participation
              at any time, for any reason, by stopping to use the site and
              services, or by contacting us at{" "}
              <a href="mailto:contact@pixxmo.com">contact@pixxmo.com</a>.
            </h4>
          </li>
          <li>
            <h4>
              Without limiting any other provision of these Terms and
              Conditions, we reserve the right to, in our sole discretion and
              without notice or liability, deny access to and use of the Site
              and the Services (including blocking certain IP addresses), to any
              person for any reason, including, but not limited to breach of any
              representation, warranty or covenant contained in these Terms and
              Conditions or of any applicable law or regulation.
            </h4>
          </li>
          <li>
            <h4>
              If we terminate or suspend your account for any reason, you are
              prohibited from registering and creating a new account under your
              name, a fake or borrowed name, or the name of any third party,
              even if you may be acting on behalf of the third party. In
              addition to terminating or suspending your account, we reserve the
              right to take appropriate legal action, including, but not limited
              to pursuing civil, criminal, and injunctive redress.
            </h4>
          </li>
        </ul>
        <br />
        <h3>
          <strong>GENERAL</strong>
          <hr />
        </h3>
        <ul>
          <li>
            <h4>
              Visiting the Site, sending us emails, and completing online forms
              constitute electronic communications. You consent to receive
              electronic communications and you agree that all agreements,
              notices, disclosures, and other communications we provide to you
              electronically, via email, and on the Site, satisfy any legal
              requirement that such communications be in writing.
            </h4>
          </li>
          <li>
            <h4>
              You hereby agree to the use of electronic signatures, documents
              and delivery methods. You hereby waive any rights or requirements
              under any statutes, regulations, rules, ordinances, or other laws
              in any jurisdiction which require an original signature or
              delivery or retention of non-electronic records.
            </h4>
          </li>
          <li>
            <h4>
              These Terms and Conditions and any policies or operating rules
              posted by us on the Site or in respect to the Services constitute
              the entire agreement and understanding between you and us.
            </h4>
          </li>
          <li>
            <h4>
              Our failure to exercise or enforce any right or provision of these
              Terms and Conditions shall not operate as a waiver of such right
              or provision.
            </h4>
          </li>
          <li>
            <h4>
              We may assign any or all of our rights and obligations to others
              at any time.
            </h4>
          </li>
          <li>
            <h4>
              We shall not be responsible or liable for any loss, damage, delay,
              or failure to act caused by any cause beyond our reasonable
              control.
            </h4>
          </li>
          <li>
            <h4>
              If any provision or part of a provision of these Terms and
              Conditions is unlawful, void, or unenforceable, that provision or
              part of the provision is deemed severable from these Terms and
              Conditions and does not affect the validity and enforceability of
              any remaining provisions.
            </h4>
          </li>
          <li>
            <h4>
              There is no joint venture, partnership, employment or agency
              relationship created between you and us as a result of these Terms
              and Conditions or use of the Site or Services.
            </h4>
          </li>
          <li>
            <h4>
              These Terms and Conditions will be governed by and construed in
              accordance with the laws of India, and you irrevocably submit to
              the exclusive jurisdiction of the courts of India.
            </h4>
          </li>
          <li>
            <h4>
              A person who is not a party to these Terms and Conditions cannot
              enforce any term of these Terms and Conditions.
            </h4>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Terms;
