import React from "react";
import { Helmet } from "react-helmet";

import Contact from "../../components/contact/Contact";

import Service1 from "../../images/assets/services.png";

import "./services.css";

// Services Icons
import Web1 from "../../images/assets/icons/Design.png";
import App1 from "../../images/assets/icons/app.png";
import SEO1 from "../../images/assets/icons/seo.png";
import CSD1 from "../../images/assets/icons/custom.png";
import PPC1 from "../../images/assets/icons/PPC.png";
import SMM11 from "../../images/assets/icons/smm.png";
import Graphic1 from "../../images/assets/icons/graphic.png";

const Services = () => {
  return (
    <div>
      <Helmet>
        <title>pixXmo | Our Services</title>
      </Helmet>
      <div className="services1">
        <h1>
          <strong>Our Services</strong>
        </h1>
        <h2>
          <strong>Be great in act, as you have been in thought</strong>
        </h2>
        <h4>
          <i>
            We at pixXmo come together to create, devise, design, and animate
            visuals and content on a varied range of platforms including
            Website, Application: Android/IOS, Promotional Videos, Product
            Photography, Graphics: Logos, Poster, Banner, 3D Modelling,
            Advertisement, Flyers, Infographics, Invitations, Brochures, Resume,
            Menu, Presentations, and a lot more. We guarantee you the most
            economical prices in the market along with the best quality
            assurance. This is the one-stop solution for all your digital
            creative needs.
          </i>
        </h4>
        {/* Main Section #list of services */}
        <div className="lservices">
          <div className="row">
            <div className="col-lg-4">
              <div className="sbox">
                <a href="/website-design-and-development">
                  <div className="sboximg">
                    <img src={Web1} alt="Website Design and Development" />
                  </div>
                  <div className="sboxtxt">
                    <h4>
                      <strong>Website Designing and Development</strong>
                    </h4>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sbox">
                <a href="/mobile-application-development">
                  <div className="sboximg">
                    <img src={App1} alt="Mobile Application Development" />
                  </div>
                  <div className="sboxtxt">
                    <h4>
                      <strong>Mobile Application Development</strong>
                    </h4>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sbox">
                <a href="/custom-software-development">
                  <div className="sboximg">
                    <img src={CSD1} alt="Custom Software Development" />
                  </div>
                  <div className="sboxtxt">
                    <h4>
                      <strong>Custom Software Development</strong>
                    </h4>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sbox">
                <a href="/graphics-designing">
                  <div className="sboximg">
                    <img src={Graphic1} alt="Graphics Designing" />
                  </div>
                  <div className="sboxtxt">
                    <h4>
                      <strong>Graphics Designing</strong>
                    </h4>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sbox">
                <a href="/search-engine-optimization">
                  <div className="sboximg">
                    <img src={SEO1} alt="Search Engine Optimization" />
                  </div>
                  <div className="sboxtxt">
                    <h4>
                      <strong>Search Engine Optimization</strong>
                    </h4>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sbox">
                <a href="/social-media-marketing">
                  <div className="sboximg">
                    <img src={SMM11} alt="Social Media Marketing" />
                  </div>
                  <div className="sboxtxt">
                    <h4>
                      <strong>Social Media Marketing</strong>
                    </h4>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sbox">
                <a href="/pay-per-click-ads">
                  <div className="sboximg">
                    <img src={PPC1} alt="Pay Per Click Management" />
                  </div>
                  <div className="sboxtxt">
                    <h4>
                      <strong>Pay Per Click Management</strong>
                    </h4>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* Ends */}
        {/* Service Description Section */}
        <div className="sdesc">
          {/* Service Description and Explore Now Btn */}
          <div className="aboutservice">
            <h3>
              <strong>Website Design and Development</strong>
            </h3>
            <h4>
              Having designed, developed, and managed a multitude of business
              websites or applications on all the popular platforms for a
              variety of industries, We have vast experience in delivering
              quality outcomes for all your needs. Your business website or
              application is a better illustration of your business and it
              should be a great blend of every ingredient which is essential to
              ensure its performance. Consult the experts at ‘pixXmo’ to get the
              best-quality business website or application that not only perform
              well on distinct browsers but also support your digital marketing
              efforts.
            </h4>
            <a href="/website-design-and-development">
              <button className="btn btnColor">Explore Now</button>
            </a>
          </div>
          {/* ends */}
          {/* Service Description and Explore Now Btn */}
          <div className="aboutservice">
            <h3>
              <strong>Mobile Application Development</strong>
            </h3>
            <h4>
              What is quicker, easier, and more accessible is probably an
              application. How many times do we hear someone saying that there
              is an app for this? Applications that are usually referred to as
              apps are taking the entire industry and how we see and access
              things by storm. We design, deploy and develop these apps at
              minimalist prices with a focus on conveying what is yours.
            </h4>
            <a href="/mobile-application-development">
              <button className="btn btnColor">Explore Now</button>
            </a>
          </div>
          {/* ends */}
          {/* Service Description and Explore Now Btn */}
          <div className="aboutservice">
            <h3>
              <strong>Custom Software Development</strong>
            </h3>
            <h4>
              Our team experts ensure a secure and fully featured custom
              software like ERP and CRM, CMS, business process automation
              system, and many more for SMEs, Startups, and businesses.
            </h4>
            <a href="/custom-software-development">
              <button className="btn btnColor">Explore Now</button>
            </a>
          </div>
          {/* ends */}
          {/* Service Description and Explore Now Btn */}
          <div className="aboutservice">
            <h3>
              <strong>Graphics Designing</strong>
            </h3>
            <h4>
              Graphics is so much more than just brand recognition. We boast of
              an amazing team of designers who not only bring your ideas to life
              but also engage and build a rapport with your audience. Our
              tremendous focus is on creating outstanding designs, striking
              logos, engaging flyers, and attractive flexes amongst other
              graphical content.
            </h4>
            <a href="/graphics-designing">
              <button className="btn btnColor">Explore Now</button>
            </a>
          </div>
          {/* ends */}
          {/* Service Description and Explore Now Btn */}
          <div className="aboutservice">
            <h3>
              <strong>Search Engine Optimization</strong>
            </h3>
            <h4>
              SEO is a proven marketing strategy. We provide Business Oriented,
              distinctive, and reliable SEO techniques for enhancing the
              presence of business online, increasing reach and engagement, and
              building brand reputation.
            </h4>
            <a href="/search-engine-optimization">
              <button className="btn btnColor">Explore Now</button>
            </a>
          </div>
          {/* ends */}
          {/* Service Description and Explore Now Btn */}
          <div className="aboutservice">
            <h3>
              <strong>Social Media Marketing</strong>
            </h3>
            <h4>
              With Platforms like Instagram. Facebook, We’ve helped startups,
              businesses expand their reach, engagement. Don’t let your
              competitors get ahead of you in this or any other aspect of DM.
            </h4>
            <a href="/social-media-marketing">
              <button className="btn btnColor">Explore Now</button>
            </a>
          </div>
          {/* ends */}
          {/* Service Description and Explore Now Btn */}
          <div className="aboutservice">
            <h3>
              <strong>Pay-Per Click Management</strong>
            </h3>
            <h4>
              Pay Per Click Management is a great way to target an audience.
              pixXmo will help you to target the right audience for the right
              price at the right time to generate more clicks.
            </h4>
            <a href="/pay-per-click-ads">
              <button className="btn btnColor">Explore Now</button>
            </a>
          </div>
          {/* ends */}
        </div>
        {/* Ends */}
        {/* Request a Quote Section */}
        <div className="servicecontact">
          <h1>
            How we can help{" "}
            <span>
              <strong>your business grow.</strong>
            </span>
          </h1>
          <div className="row">
            <div className="col-lg-6">
              <div className="serviceimg">
                <img src={Service1} alt="Our Services" />
              </div>
            </div>
            <div className="col-lg-6 servicecnt">
              <Contact />
            </div>
          </div>
        </div>
        {/* Ends */}
      </div>
    </div>
  );
};

export default Services;
